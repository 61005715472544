import React,  { useState, useEffect, useRef,  } from 'react';
import AfiliadosBike from '../../layout/AfiliadosBike';
import AfiliadosUber from '../../layout/AfiliadosUber';
import AfiliadosSuplementos from '../../layout/AfiliadoSuplementos';
import { fontSize } from '@mui/system';
import { WhatsApp } from '@mui/icons-material';
import { ContainerLogin, WrapperControlPanel, ControlPanel } from './style';
import Header from '../../layout/Header';
import TabBar from '../../layout/TabBar';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Face6Icon from '@mui/icons-material/Face6';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PaymentIcon from '@mui/icons-material/Payment';
//import { EcommerceProducts } from '../../layout/Produtos';
//import { EcommerceCostBreakdown } from '../../layout/Afiliados/EcommerceCostBreakdown';
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Adelmo from '../../assets/image/afiliados/diagnose.jpg';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Chip from '@mui/material/Chip';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AccessibilityNewIcon from '@mui/icons-material/DirectionsRun';
import Iconebioimedancia from '@mui/icons-material/AccessibilityNew';
import Iconediagnose from '@mui/icons-material/Assignment';
import Sac from '@mui/icons-material/HeadsetMic';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Container } from '@mui/material';
import Nivel from '@mui/icons-material/Person';
import Series from '@mui/icons-material/Addchart';
import Repeticoes from '@mui/icons-material/SentimentSatisfiedAlt';
import Recuperacao from '@mui/icons-material/AccessTime';
import Seta from '@mui/icons-material/KeyboardDoubleArrowRight';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Download from '@mui/icons-material/CloudDownload';
//import YouTubePlayer from 'youtube-player';
import ListarExercicios from "../../layout/ListarExercicios";
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  SvgIcon,
  Switch,
  CardHeader,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';


const selectedtab = {
  backgroundColor: 'red',
  color: 'white',
  padding: '10px',
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));


const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
} 

function SubTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`sub-tabpanel-${index}`}
    aria-labelledby={`sub-tab-${index}`}
    className={value === index ? "selectedtab" : ""}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 0 }}>
        <Typography style={{ fontSize: '9px' }}>{children}</Typography>

      </Box>
    )}
  </div>
  );
}



SubTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};




function SubTab(props) {
  const [currentTab, setCurrentTab] = useState(0);
  const { label, subTabs, style } = props;

  const handleTabClick = (index) => {
    setCurrentTab(index);
  };
  

  return (
    <div style={style}>
      <div>
        {subTabs.map((tab, index) => (
          <label
            key={index}
            style={{
             
              backgroundColor: currentTab === index ? '#e73353' : '#999',
              fontSize: currentTab === index ? '12px' : '12px',
              padding: currentTab === index ? '15px' : '15px',
              borderRadius: currentTab === index ? '500px' : '500px',
              color: currentTab === index ? '#fff' : '#fff',
            }}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </label>
        ))}
      </div>
      <div>
        {subTabs.map((tab, index) => (
          <div key={index} hidden={currentTab !== index}>
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

SubTab.propTypes = {
  label: PropTypes.string.isRequired,
  subTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};


  
  export default function Treino() {
    const [value, setValue] = React.useState(0);
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange2 = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  
    
    const columns: GridColDef[] = [
      { field: 'col1', headerName: 'Data', width: 20, flex: 1 },
      { field: 'col2', headerName: 'Método', width: 20, flex: 1 },
      { field: 'col3', headerName: 'Tratamento', width: 20, flex: 1 },
      { field: 'col4', headerName: 'Ver', width: 20, flex: 1 },
    ];

    const rows = [
      { id: 1, col1: '01/01/2022', col2: 'Método 1', col3: 'Tratamento 1', col4: 'Ver 1' },
      { id: 2, col1: '02/01/2022', col2: 'Método 2', col3: 'Tratamento 2', col4: 'Ver 2' },
      { id: 3, col1: '03/01/2022', col2: 'Método 3', col3: 'Tratamento 3', col4: 'Ver 3' },
    ];
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


  const useStyles = makeStyles((theme) => ({
    selected: {
      backgroundColor: 'red',
      color: 'white',
      fontWeight: 'bold',
    },
  }));
  const baseyoutube = 'https://www.youtube.com/embed/vtH7V2ccxzg?controls=0';
  const embedUrl = `${baseyoutube}?controls=0`;
  //const embedUrl = `https://www.youtube.com/embed/pzLwdoC2Nqc?controls=0`;
  
    return (
        <ContainerLogin>
        <WrapperControlPanel>
          <ControlPanel>
            <Header />
            <p></p> <p></p><br/>
            <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
  onChange={handleChange}
  textColor="#fff"
  borderRadius="3"
  indicatorColor="purple"
  aria-label="secondary tabs example"
  value={value}
  sx={{
    display: "flex",
    justifyContent: "center",
    width: "100%", // Adiciona largura de 100% para ocupar todo o espaço disponível
    alignItems: "center",    
  }}
>
  <Tab
    label="Meu Treino"
    icon={<AccessibilityNewIcon />}
    {...a11yProps(0)}
    sx={{
      background: value === 0 ? "#fff" : "",
      color: value === 0 ? "#ff2426" : "#999",
      boxShadow: value === 0 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      padding: value === 0 ? "0" : "0",
      borderRadius: 10,
      fontSize: "0.600rem",
      
    }}
    value={0}
  />
  <Tab
    label="Scanner Corporal"
    icon={<Iconebioimedancia />}
    {...a11yProps(1)}
    sx={{
      background: value === 1 ? "#fff)" : "",
      color: value === 1 ? "#ff2426" : "#999",
      boxShadow: value === 1 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      borderRadius: 10,
      fontSize: "0.600rem",
    }}
    value={1}
  />
  <Tab
    label="Diagnose"
    icon={<Iconediagnose />}
    {...a11yProps(2)}
    sx={{
      background: value === 2 ? "#fff" : "",
      color: value === 2 ? "#ff2426" : "#999",
      boxShadow: value === 2 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      borderRadius: 10,
      fontSize: "0.600rem",
    }}
    value={2}
  />
</Tabs>

        </Box>
        <TabPanel value={value} index={0}  >
      
      
      <Card style={{ padding: '0px !important'  }}>
        <CardContent style={{ padding: '15px'  }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={4}             
            >
       
      
       <a href="https://api.whatsapp.com/send?phone=553141411962&text=Ol%C3%A1%20estou%20no%20Aplicativo%20em%20Casa%20e%20estou%20com%20d%C3%BAvida" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
  <Button
    variant="contained"
    size="large"
    startIcon={<Sac />}
    style={{
      backgroundColor: 'rgb(241, 53, 83)',
      width: '100%',
      borderRadius: '400px',
      padding: '15px',
      fontSize: '13px'
    }}
  >
    FALAR COM SAC
  </Button>
</a>


< br />< br />

<a href="https://api.whatsapp.com/send?phone=553141411962&text=Ol%C3%A1%20Professor%20,%20estou%20vindo%20da%20pagina%20de%20treino%20do%20Aplicativo" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

<Button variant="contained" size="large" startIcon={<WhatsAppIcon />}  style={{ backgroundColor: '#a682c3', width: '100%', fontSize: '13px', borderRadius: '400px', padding: '15px'  }}>
FALAR COM O PROFESSOR
</Button></a><br /><br />
<ListarExercicios />

      <br />
   
      <div style={{  fontSize: '12px', textAlign: 'center', color: 'rgb(241, 53, 83)'}}>Meu Lembrete</div>
      <TextField
  id="long-text-field"
  label=""
  multiline
  maxRows={8}
  placeholder="Você ainda não tem um Lembrete, fique a vontade este espaço é seu!"
  variant="outlined"
  fullWidth
  style={{ backgroundColor: '#fff', borderRadius: '15px' }}
  InputProps={{
    style: {
      color: '#000000',
      fontSize: '12px',
    },
  }}
/></Grid>
          </Grid></CardContent>
      </Card>
          
      <br /><br /><br /><br /><br /><br /><br /><br />
        </TabPanel>


        <TabPanel value={value} index={1}>

       
      <Card> <CardContent>  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
     
        <br /><br /><a href="https://pratiqueemcasa.com.br/sistema/conteudo/exames/768926Lais%20Clemente.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
<Button variant="contained" size="large" startIcon={<Download />}  style={{ backgroundColor: 'rgb(241, 53, 83)', width: '100%', fontSize: '13px', padding: '15px', borderRadius: '400px'   }}>
BAIXAR EXAME
</Button></a>
    </div>
   </CardContent>
      </Card>

      
        </TabPanel>


        <TabPanel value={value} index={2}>
        <Card>
        <DataGrid rows={rows} columns={columns} style={{ fontSize: '10px' }} />
        </Card>
        </TabPanel>
      </Box><TabBar />
        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
    );
  }
  

