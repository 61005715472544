import { InputForm } from './styles';

function Input({ type, name, placeholder, handleOnChange, value }) {
    return (
            <InputForm
                type={type} 
                name={name} 
                id={name} 
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
            />
    )
}

export default Input;