import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../assets/colors";
import Image from "../../assets/image/image-change-exercises.svg";
import {
  ExerciseCol,
  ExerciseContainer,
  ExerciseEdit,
  ExerciseImage,
  ExerciseImageContainer,
  ExerciseInfoCol,
  ExerciseInfoContainer,
  ExerciseInfoRow,
  ExerciseInfoTitle,
  ExerciseInfoValue,
  ExerciseSelect,
  ExerciseTitle,
} from "./style";

function Exercise({ data}) {
  const navigate = useNavigate();

  return (
    <ExerciseContainer>
    
      <ExerciseImageContainer>
        <ExerciseImage src={data?.capa} />
      </ExerciseImageContainer>
      <ExerciseInfoContainer>
        <ExerciseInfoRow>
          {data?.titulo}<br /><br />
          <ExerciseEdit
        onClick={() => navigate("/exercises/change", { state: data })}
      >
        &nbsp;&nbsp;VER CURSO&nbsp;&nbsp;
      </ExerciseEdit> 
        </ExerciseInfoRow>
      
      
       
    
      </ExerciseInfoContainer>


    
    </ExerciseContainer>
  );
}

export default Exercise;
