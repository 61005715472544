import styled from "styled-components";
import { Link } from "react-router-dom";
import { Colors } from "../../assets/colors";

const WrapperButton = styled.div`
  width: 100%;
`;

const Button = styled.button`
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 16px 0;
  background-color: ${(props) =>
    props.disabled ? Colors.gray1 : 'rgb(241, 53, 83)'};
  color: #fff;
  
  font-size: 18px;
  text-decoration: none;
  transition: 0.5s;
  cursor: pointer;
  border: none;
  border-radius: 8px;

  :hover {
    background-color: ${Colors.gray1};
  }
`;

export { WrapperButton, Button };
