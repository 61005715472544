import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


import {
  ContainerLogin,
  WrapperControlPanel,
  ControlPanel,
  WrapperContent,
  ButtonTabBar
} from "./style";
import Live from '../../layout/Aula';
import Header from "../../layout/Header";
import TabBar from "../../layout/TabBar";
import MyProgress from "../../layout/home/MyProgress";
import MyDisposition from "../../layout/home/MyDisposition";
import LastMonth from "../../layout/home/LastMonth";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Foto1 from '../../assets/image/banners-topo/01.png';
import Foto2 from '../../assets/image/banners-topo/02.png';
import Foto3 from '../../assets/image/banners-topo/cliente2.png';
import Foto4 from '../../assets/image/banners-topo/04.webp';
import Foto5 from '../../assets/image/banners-topo/05.webp';
import Foto6 from '../../assets/image/banners-topo/06.webp';
import Foto7 from '../../assets/image/banners-topo/07.webp';
import Foto8 from '../../assets/image/banners-topo/08.webp';
import Foto9 from '../../assets/image/banners-topo/09.webp';
import Fotomeditacao from '../../assets/image/banners-topo/novo/meditacao.png';
import Fotocoletivas from '../../assets/image/banners-topo/novo/coletivas.png';
import Fotohorarios from '../../assets/image/banners-topo/novo/horarios.png';
import Fotoblog from '../../assets/image/banners-topo/novo/blog.png';
import Fotopower from '../../assets/image/banners-topo/novo/powercycle.png';
import Fotosac from '../../assets/image/banners-topo/novo/sac.png';
import Live1 from '../../assets/image/live/01.jpg';
import Live2 from '../../assets/image/live/02.jpg';

//import $ from 'jquery';
import "../../assets/css/qdb_qf_owl.carousel.css";
import "../../assets/css/qdb_qf_owl.theme.css";
//import "../../assets/js/qdb_qf_owl.carousel.js";

const Imagem1 = <img src={Foto1}  style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem2 = <img src={Foto2} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem3 = <img src={Foto3} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem4 = <img src={Foto4} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem5 = <img src={Foto5} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem6 = <img src={Foto6} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem7 = <img src={Foto7} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem8 = <img src={Foto8} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem9 = <img src={Foto9} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Meditacao = <img src={Fotomeditacao}  style={{ width: '100%' }} alt="Meditação Guiada" />;
const Coletivas = <img src={Fotocoletivas}  style={{ width: '100%' }} alt="Meditação Guiada" />;
const Horarios = <img src={Fotohorarios}  style={{ width: '100%' }} alt="Meditação Guiada" />;
const Blog = <img src={Fotoblog}  style={{ width: '100%' }} alt="Meditação Guiada" />;
const Powercycle = <img src={Fotopower}  style={{ width: '100%' }} alt="Meditação Guiada" />;
const Sac = <img src={Fotosac}  style={{ width: '100%' }} alt="Meditação Guiada" />;


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  paddingTop: '0px !important',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // Adiciona sombra zero
}));

function Home() {
  
  const data = [
    {
      date: '2023-05-01',
      time: '14:30',
      photo: Live1,
    },
    {
      date: '2023-05-24',
      time: '18:00',
      photo: Live2,
    },
    {
      date: '2023-05-24',
      time: '18:00',
      photo: Live1,
    },
     
    
  ];

  return (
    <ContainerLogin style={{ padding: '10px', paddingTop: '0px' }}>
    <WrapperControlPanel>
        <ControlPanel >
            <Header />
           <p></p><br /> <Live data={data}  />
           <br /> <Typography
                  color="#777"
                  variant="body2"
                  fontSize="12px"
                  marginLeft="15px"
                  fontWeight="800"
                >
              VOCÊ, FELIZ E SAUDÁVEL
                </Typography> <Typography
                  color="#555"
                  variant="body2"
                  fontSize="12px"
                  marginLeft="15px"
                >
              Exercícios e conteúdos para você
                </Typography>
                       

    <Grid style={{padding: '10px'}} container spacing={1}>
  
  <Grid item xs={6} md={3} style={{ paddingTop: '0px !important', boxShadow: '0 !important' }}>
    <Item>{Meditacao}</Item>
  </Grid>
  <Grid item xs={6} md={3} style={{ paddingTop: '0px !important' }}>
  <Link to="/Coletivas"> <Item>{Coletivas}</Item></Link>
  </Grid>

  
  <Grid item xs={6} md={3} style={{ paddingTop: '0px !important', boxShadow: '0 !important' }}>
    <Item>{Powercycle}</Item>
  </Grid>
  <Grid item xs={6} md={3} style={{ paddingTop: '0px !important' }}>
    <Item>{Blog}</Item>
  </Grid>



  <Grid item xs={6} md={3} style={{ paddingTop: '0px !important', boxShadow: '0 !important' }}>
    <Item>{Sac}</Item>
  </Grid>
  <Grid item xs={6} md={3} style={{ paddingTop: '0px !important' }}>
    <Item>{Horarios}</Item>
  </Grid>


</Grid>
<br /><br /><br /><br /><br /><br />

            <TabBar />
        </ControlPanel>
    </WrapperControlPanel>
</ContainerLogin>

  );
}

export default Home;


/*
import React from "react";
import {
  ContainerLogin,
  WrapperControlPanel,
  ControlPanel,
  WrapperContent,
} from "./style";

import Header from "../../layout/Header";
import TabBar from "../../layout/TabBar";
import MyProgress from "../../layout/home/MyProgress";
import MyDisposition from "../../layout/home/MyDisposition";
import LastMonth from "../../layout/home/LastMonth";

function Home() {
  return (
    <ContainerLogin>
      <WrapperControlPanel>
        <ControlPanel>
          <Header />
          <WrapperContent>
            <MyProgress />
            <MyDisposition />
            <LastMonth />
          </WrapperContent>
          <TabBar />
        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
  );
}

export default Home;
*/
