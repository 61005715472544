export const Colors = {
  background: "#0F0F0F",
  inactive: "#929292",
  white: "#ffffff",
  gray1: "#B8B8B8",
  gray2: "#1C1C1C",
  gray3: "#f7f7f7",
  gray4: "#494949",
  red_light: "#F13553",
  green: "#0E9E36",
};