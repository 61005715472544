import React from 'react';
import { ContainerLogin, WrapperControlPanel, ControlPanel } from './style';
import Header from '../../layout/Header';
import TabBar from '../../layout/TabBar';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LogoUnipower from '../../assets/image/logounipower.png';



import { useState, useEffect } from 'react';

import {
   Card,
  CardContent,
  CardHeader,
  Stack,
  Unstable_Grid2 as Grid
} from  '@mui/material';
import LayoutUnipower from '../../layout/LayoutUnipower';


  
  export default function Afiliados() {
    const [value, setValue] = React.useState(0);

    return (
        <ContainerLogin>
        <WrapperControlPanel>
          <ControlPanel>
            <Header />
            <p></p> <p></p><br/>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  <img src={LogoUnipower} style={{ maxWidth: "100%", width: "70%" }} />
 
</div> <Typography variant="h6" style={{ color: '#888', fontSize: '12px', textAlign: 'center' }}>
Cursos disponíveis para você!<br /><br />

O seu acesso na plataforma é:<br />
<b>Usuário:</b> Seu email<br />
<b>Senha:</b> pratiqueemcasa<br /><br />
      </Typography>
      <Card  style={{
  width: "90%",
  zIndex: "99999",
  borderRadius: "15px 15px 15px 15px",
  margin: "0 auto", // centraliza horizontalmente
  display: "flex", // permite centralizar verticalmente
  alignItems: "center", // centraliza verticalmente
  justifyContent: "center" // centraliza horizontalmente
}}><CardContent>    <div style={{ height: '100%', width: "100%" }}>


<LayoutUnipower />


    </div></CardContent> </Card>      

    
      </Box></Box><TabBar />
        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
    );
  }
  

