




import React from "react";
import {
  ContainerLogin,
  WrapperControlPanel,
  ControlPanel,
  InputIcon,
  Image,
  LinkForgotPassword,
} from "./style";
import { Link, useNavigate } from "react-router-dom";

import Input from "../../form/Input/Input";
import LinkButton from "../../form/LinkButton";
import Logo from "../../assets/image/logopratique.png";
import Background from "../../assets/image/fundopratique.jpg";

import Eye from "../../assets/icones/login/eye.svg";
import Usuarios from "../../../api/services/Usuarios";

function Home() {
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    await Usuarios.login(
      event.target.email.value,
      event.target.password.value
    ).then((res) => navigate("/home"));
  };

  return (
    <ContainerLogin onSubmit={handleSubmit}>
      <WrapperControlPanel
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <ControlPanel>
          <Image>
            <img src={Logo} alt="" />
          </Image>
          <div>
            <Input name="email" type="email" placeholder="E-mail ou CPF" />
            <InputIcon>
              <Link>
                <img className="img" src={Eye} alt="" />
              </Link>
              <Input
                name="password"
                type="password"
                placeholder="Senha de acesso "
              />
            </InputIcon>
            <LinkForgotPassword to="#">
              <p>Esqueci a senha</p>
            </LinkForgotPassword>
            <LinkButton to="/home" type="submit" text="Entrar" />
            <p style={{fontSize: '12px'}}className="textEnd">
              Ao clicar em ENTRAR você concorda com os nossos termos
            </p>
          </div>
        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
  );
}

export default Home;

