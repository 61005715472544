import React from 'react';
import { ContainerLogin, WrapperControlPanel, ControlPanel } from './style';
import Header from '../../layout/Header';
import TabBar from '../../layout/TabBar';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tadeu from  '../../assets/image/avatar.png';
import Face6Icon from '@mui/icons-material/Face6';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PaymentIcon from '@mui/icons-material/Payment';
//import { EcommerceProducts } from '../../layout/Produtos';
//import { EcommerceCostBreakdown } from '../../layout/Afiliados/EcommerceCostBreakdown';
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Adelmo from '../../assets/image/afiliados/diagnose.jpg';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Chip from '@mui/material/Chip';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import Pagamentos from '@mui/icons-material/CreditScore';
import Colaborador from '@mui/icons-material/SentimentSatisfiedAlt';
import Colaborador2 from '@mui/icons-material/Handshake';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  SvgIcon,
  Switch,
  CardHeader,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';



import { useState, useEffect } from 'react';


import AfiliadosBike from '../../layout/AfiliadosBike';
import AfiliadosUber from '../../layout/AfiliadosUber';
import AfiliadosSuplementos from '../../layout/AfiliadoSuplementos';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import Foto1 from '../../assets/image/banners-topo/01.png';
import Foto2 from '../../assets/image/banners-topo/02.png';
import Foto3 from '../../assets/image/banners-topo/cliente2.png';
import Foto4 from '../../assets/image/banners-topo/04.webp';
import Foto5 from '../../assets/image/banners-topo/05.webp';
import Foto6 from '../../assets/image/banners-topo/06.webp';
import Foto7 from '../../assets/image/banners-topo/07.webp';
import Foto8 from '../../assets/image/banners-topo/08.webp';
import Foto9 from '../../assets/image/banners-topo/09.webp';


const Imagem1 = <img src={Foto1}  style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem2 = <img src={Foto2} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem3 = <img src={Foto3} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem4 = <img src={Foto4} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem5 = <img src={Foto5} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem6 = <img src={Foto6} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem7 = <img src={Foto7} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem8 = <img src={Foto8} style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem9 = <img src={Foto9} style={{ width: '100%' }} alt="Descrição da imagem" />;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  paddingTop: '0px !important',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // Adiciona sombra zero
}));

const selectedtab = {
  backgroundColor: 'red',
  color: 'white',
  padding: '10px',
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
} 

function SubTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`sub-tabpanel-${index}`}
    aria-labelledby={`sub-tab-${index}`}
    className={value === index ? "selectedtab" : ""}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 0 }}>
        <Typography style={{ fontSize: '9px' }}>{children}</Typography>

      </Box>
    )}
  </div>
  );
}



SubTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};




function SubTab(props) {
  const [currentTab, setCurrentTab] = useState(0);
  const { label, subTabs, style } = props;

  const handleTabClick = (index) => {
    setCurrentTab(index);
  };
  

  return (
    <div style={style}>
      <div>
        {subTabs.map((tab, index) => (
          <label
            key={index}
            style={{
             
              backgroundColor: currentTab === index ? '#e73353' : '#999',
              fontSize: currentTab === index ? '12px' : '12px',
              padding: currentTab === index ? '15px' : '15px',
              borderRadius: currentTab === index ? '500px' : '500px',
              color: currentTab === index ? '#fff' : '#fff',
            }}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </label>
        ))}
      </div>
      <div>
        {subTabs.map((tab, index) => (
          <div key={index} hidden={currentTab !== index}>
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

SubTab.propTypes = {
  label: PropTypes.string.isRequired,
  subTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};


  
  export default function Conta() {
    const [value, setValue] = React.useState(0);

    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      paddingTop: '0px !important',
      ...theme.typography.body2,
      padding: theme.spacing(0),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow: 'none', // Adiciona sombra zero
    }));
    

    
    const rows: GridRowsProp = [
      { id: 1, col1: 'R$ 23,50', col2: '23/02/2023' },
      { id: 2, col1: 'R$ 50,00', col2: '29/02/2023' },
      { id: 3, col1: 'R$ 115,20', col2: '15/03/2023'},
    
    ];
    
    const columns: GridColDef[] = [
      { field: 'col1', headerName: 'Valor', width: 20, flex: 1 },
      { field: 'col2', headerName: 'Data', width: 20, flex: 1 },
        ];

    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


  const useStyles = makeStyles((theme) => ({
    selected: {
      backgroundColor: 'red',
      color: 'white',
      fontWeight: 'bold',
    },
  }));


  
    return (
        <ContainerLogin>
        <WrapperControlPanel>
          <ControlPanel>
            <Header />
            <p></p> <p></p><br/>
            <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
  onChange={handleChange}
  textColor="#fff"
  borderRadius="3"
  indicatorColor="purple"
  aria-label="secondary tabs example"
  padding="0"
  value={value}
  sx={{
    display: "flex",
    justifyContent: "center",
    width: "100%", // Adiciona largura de 100% para ocupar todo o espaço disponível
    alignItems: "center",
  }}
>
  <Tab
    label="Dados"
    icon={<Face6Icon />}
    {...a11yProps(0)}
    sx={{
      background: value === 0 ? "#fff" : "",
      color: value === 0 ? "#ff2426" : "#999",
      boxShadow: value === 0 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      padding: value === 0 ? "0" : "0",
      borderRadius: 10,
      minWidth: value === 0 ? "70px" : "70px",
      fontSize: "0.600rem",
    }}
    value={0}
  />
  <Tab
    label="Plano"
    icon={<FitnessCenterIcon />}
    {...a11yProps(1)}
    sx={{
      background: value === 1 ? "#fff)" : "",
      color: value === 1 ? "#ff2426" : "#999",
      boxShadow: value === 1 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      borderRadius: 10,
      padding: value === 1 ? "0" : "0",
      minWidth: value === 1 ? "70px" : "70px",

      fontSize: "0.600rem",
    }}
    value={1}
  />
  <Tab
    label="Pagamentos"
    icon={<PaymentIcon />}
    {...a11yProps(2)}
    sx={{
      background: value === 2 ? "#fff" : "",
      color: value === 2 ? "#ff2426" : "#999",
      boxShadow: value === 2 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      borderRadius: 10,
      padding: value === 2 ? "0" : "0",
     
      fontSize: "0.600rem",
    }}
    value={2}
  />

<Tab
    label="Colaborador"
    icon={<Colaborador />}
    {...a11yProps(3)}
    sx={{
      background: value === 3 ? "#fff" : "",
      color: value === 3 ? "#ff2426" : "#999",
      boxShadow: value === 3 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      borderRadius: 10,
      padding: value === 3 ? "0" : "0",
      minWidth: value === 1 ? "100px" : "100px",
      fontSize: "0.600rem",
    }}
    value={3}
  />
</Tabs>

        </Box>
        <TabPanel value={value} index={0}>
      
      
      <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={4}
              
            >


          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      < AssignmentIndIcon style={{ color: 'rgb(241, 53, 83)' }} />
      <Typography variant="h6" style={{ color: 'rgb(241, 53, 83)', fontSize: '14px', textAlign: 'center' }}>
      &nbsp;Meus Dados
      </Typography>
    </div>
    <br />
              <Stack spacing={3}>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <Box
                    sx={{
                      borderColor: 'neutral.300',
                      borderRadius: '50%',
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      p: '4px'
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '50%',
                        height: '100%',
                        width: '100%',
                        position: 'relative'
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          backgroundColor:'#000',
                          borderRadius: '50%',
                          color: 'common.white',
                          cursor: 'pointer',
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                          left: 0,
                          opacity: 0,
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          zIndex: 1,
                          '&:hover': {
                            opacity: 1
                          }
                        }}
                      >
                        <Stack
                          alignItems="center"
                          direction="row"
                          spacing={1}
                        >
                          <SvgIcon color="inherit">
                            <PaymentIcon />
                          </SvgIcon>
                          <Typography
                            color="inherit"
                            variant="subtitle2"
                            sx={{ fontWeight: 700 }}
                          >
                            Select
                          </Typography>
                        </Stack>
                      </Box>
                      
                      <Avatar
                        src={Tadeu}
                        sx={{
                          height: 70,
                          width: 70
                        }}
                      >
                        <SvgIcon>
                        <PaymentIcon />
                        </SvgIcon>
                      </Avatar>
                    </Box>
                  </Box>
                  <Button variant="contained" size="small"  style={{ backgroundColor: 'rgb(241, 53, 83)', fontSize: '10px' }}>
          Atualizar
        </Button>
                </Stack>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                  <TextField
                    defaultValue={Face6Icon}
                    label="Meu Nome"
                    value="Tadeu Madureira"
                    sx={{ flexGrow: 1,  '& input': {
                      height: 12,
                      fontSize: '12px',
                      color: '#999',
                    } }}
                  />
                   <Button variant="contained" size="small"  style={{ backgroundColor: 'rgb(241, 53, 83)', fontSize: '10px' }}>
          Atualizar
        </Button>
                </Stack>

                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                  <TextField
                    defaultValue={Face6Icon}
                    label="Senha"
                    value="*********"
                    sx={{ flexGrow: 1,  '& input': {
                      height: 12,
                      fontSize: '12px',
                      color: '#999',
                      
                    } }}
                  />
                   <Button variant="contained" size="small"  style={{ backgroundColor: 'rgb(241, 53, 83)', fontSize: '10px' }}>
          Atualizar
        </Button>
                </Stack>
                <Stack
                  alignItems="center"
                  direction=""
                  spacing={2}
                  sx={{ width: '100%' }}
                >
                  <TextField
  defaultValue={Face6Icon}
  disabled
  label="Meu Email"
  value="pratadeu@gmail.com"
  required
  sx={{
    flexGrow: 1,
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'dashed'
    },
    '& input': {
      height: 12,
      fontSize: '12px',
      color: '#999',
    }
  }}
/>

                
                </Stack>
              </Stack>
              
            </Grid>
          </Grid>
        </CardContent>
      </Card>
<br /><br />
      <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={4}
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      < DeleteForeverIcon style={{ color: 'rgb(241, 53, 83)' }} />
      <Typography variant="h6" style={{ color: 'rgb(241, 53, 83)', fontSize: '16px', textAlign: 'center' }}>
      &nbsp;Deletar Conta
      </Typography>
    </div>
            </Grid>
            <Grid
              xs={12}
              md={8}
            >
              <Stack
                alignItems="flex-start"
                justifyContent="center" // Adicionando justifyContent
                spacing={3}
              >
                <Typography variant="subtitle1" style={{ textAlign: 'center', fontSize: '12px', color: '#777' }}>
                Exclua sua conta e todos os seus dados de origem. Isso é irreversível.

                </Typography>
                <Button  style={{ width: '100%' }}
                
                  color="error"
                  variant="outlined"
                >
                  Deletar Conta
                </Button>
              </Stack>
            </Grid>
          </Grid><br /><br />     
        </CardContent>
      </Card>
      <br /><br /><br /><br /><br /><br /><br /><br />
        </TabPanel>


        <TabPanel value={value} index={1}>

       
      <Card> <CardContent>  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
     
        <br /><br /><Typography variant="h6" style={{ color: '#999', fontSize: '13px', textAlign: 'center' }}>
     Meu Plano é:
      </Typography>
    </div>
   
         <div style={{ height: 300, width: "100%" }}>
         <Stack direction="row" spacing={1}>
      <Chip icon={<FavoriteIcon  style={{ color: 'rgb(241, 53, 83)' }}  />} label="COMBO DA SAÚDE" style={{ color: 'rgb(241, 53, 83)', padding: '25px',  alignItems: 'center', justifyContent: 'center', width: '100%', boxShadow: 'rgb(220 220 220) 0px 8px 10px', fontSize: '12px'  }} />
      </Stack>
      <br /><br /><br /><br />
     
      <Typography variant="h6" style={{ color: '#999', fontSize: '13px', textAlign: 'center' }}>
     Minha Unidade é:
      </Typography>
      <Stack direction="row" spacing={1}>
      <Chip icon={<PersonPinCircleIcon  style={{ color: 'rgb(241, 53, 83)' }}  />} label="CACHOEIRINHA" style={{ color: 'rgb(241, 53, 83)', padding: '25px',  alignItems: 'center', justifyContent: 'center', width: '100%', boxShadow: 'rgb(220 220 220) 0px 8px 10px' , fontSize: '12px'  }} />
      </Stack>

    </div> </CardContent>
      </Card>

      
        </TabPanel>


        <TabPanel value={value} index={2}>
        <Card>
       <br /> <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      < Pagamentos style={{ color: 'rgb(241, 53, 83)' }} />
      <Typography variant="h6" style={{ color: 'rgb(241, 53, 83)', fontSize: '14px', textAlign: 'center' }}>
      &nbsp;Minhas Mensalidades
      </Typography>
    </div><br />
        <DataGrid rows={rows} columns={columns}  style={{ fontSize: '10px' }}  />
        </Card>
        </TabPanel>


        <TabPanel value={value} index={3}>
        <Card>
       <br /> <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      < Colaborador2 style={{ color: 'rgb(241, 53, 83)' }} />
      <Typography variant="h6" style={{ color: 'rgb(241, 53, 83)', fontSize: '14px', textAlign: 'center' }}>
      &nbsp; Olá Colaborador!
      </Typography>
    </div><br />
    <Grid container spacing={2}>
  <Grid item xs={12} md={12} style={{ paddingTop: '0px !important', boxShadow: '0' }}>
    <Item>{Imagem1}</Item>
  </Grid>
  <Grid item xs={12} md={3} style={{ paddingTop: '0px !important' }}>
  <Link to="/unipower">
    <Item>{Imagem4 } </Item>
  </Link>
</Grid>

<Grid item xs={12} md={3} style={{ paddingTop: '0px !important' }}>
  <Link to="/canal">
    <Item>{Imagem5}</Item>
  </Link>
</Grid>

</Grid>
        </Card><br /><br /><br /><br /><br /><br /><br /><br />
        </TabPanel>


      </Box><TabBar />
      


        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
    );
  }
  

