import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";

import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import Workouts from "./components/pages/Workouts";
import FitDay from "./components/pages/FitDay";
import Dash from "./components/pages/Dash";
import Conta from "./components/pages/Conta";
import Treinoprime from "./components/pages/Treinoprime";
import Treinonutri from "./components/pages/Treinonutri";
import Afiliados from "./components/pages/Afiliados";
import Points from "./components/pages/Points";
import Profile from "./components/pages/Conta";
import Treino from "./components/pages/Treino";
import Bike from "./components/pages/Bike";
import Unipower from "./components/pages/Unipower";
import Canal from "./components/pages/Canal";
import Coletivas from "./components/pages/Coletivas";
import Jump from "./components/pages/Jump";
import Yoga from "./components/pages/Yoga";
import Recomendations from "./components/pages/Recomendations";
import DaysExercises from "./components/pages/DaysExercises";
import ChangeExercises from "./components/pages/ChangeExercises";
import WorkoutC from "./components/pages/WorkoutC";
import DaysWorkout from "./components/pages/DaysWorkout";
import EvaluateInstructor from "./components/pages/EvaluateInstructor";
import CompleteWorkout from "./components/pages/CompleteWorkout";
import ExercisesList from "./components/pages/ExercisesList";


function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/workouts" element={<Workouts />} />
          <Route exact path="/fitday" element={<FitDay />} />
          <Route exact path="/dash" element={<Dash />} />
          <Route exact path="/treinoprime" element={<Treinoprime />} />
          <Route exact path="/treinonutri" element={<Treinonutri />} />
          <Route exact path="/afiliados" element={<Afiliados />} />
          <Route exact path="/coletivas" element={<Coletivas />} />
          <Route exact path="/jump" element={<Jump />} />
          <Route exact path="/yoga" element={<Yoga />} />
          <Route exact path="/points" element={<Points />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/recommendations" element={<Recomendations />} />
          <Route exact path="/days-exercises" element={<DaysExercises />} />
          <Route exact path="/exercises/list" element={<ExercisesList />} />
          <Route exact path="/exercises/change" element={<ChangeExercises />} />
          <Route exact path="/conta" element={<Conta />} />
          <Route exact path="/treino" element={<Treino />} />
          <Route exact path="/bike" element={<Bike />} />
          <Route exact path="/unipower" element={<Unipower />} />
          <Route exact path="/canal" element={<Canal />} />
          {/* <Route exact path="exercises">
            <Route
              exact
              path="change/:exercise"
              element={<ChangeExercises />}
            /> */}
            {/* <Route exact path="list" element={<ExercisesList />} /> */}
          {/* </Route> */}
          {/* <Route exact path="/change-exercises" element={<ChangeExercises />} /> */}
          <Route exact path="/workout-c" element={<WorkoutC />} />
          <Route exact path="/days-workout" element={<DaysWorkout />} />
          <Route
            exact
            path="/evaluate-instructor"
            element={<EvaluateInstructor />}
          />
          <Route exact path="/complete-workout" element={<CompleteWorkout />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
