import React, { Component } from 'react';
import moment from 'moment';
import './Slider.css'; 

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      data: this.props.data,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { currentSlide, data } = this.state;
      const lastIndex = data.length - 1;
      const shouldReset = currentSlide === lastIndex;
      const index = shouldReset ? 0 : currentSlide + 1;
      this.setState({ currentSlide: index });
    }, 5000); // advance slide every 5 seconds
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { data, currentSlide } = this.state;
    const slideWidth = 100 / data.length;
    const transform = `translateX(-${currentSlide * 100}%)`;
    const wrapperStyle = {

      display: 'flex',
      width: '100%',
      transform,
      transition: '0.5s ease-out, opacity 0.5s ease-out',
    };
    return (
      <div className="slider">
        <div className="slider-wrapper" style={wrapperStyle}>
          {data.map((item, index) => (
            <div key={index} className={`slider-slide ${index === currentSlide ? 'slider-slide-active slider-slide-current' : ''}`} style={{ width: '100%', height: '200px', backgroundImage: `url(${item.photo})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
              {/* <h3>{item.date} às {item.time}</h3>
              <p>{item.countdown} para começar</p> */}
            </div>
          ))}
        </div>
        <div className="slider-dots">
          {data.map((item, index) => (
            <span key={index} className={`slider-dot ${index === currentSlide ? 'slider-dot-active' : ''}`} onClick={() => this.setState({ currentSlide: index })}></span>
          ))}
        </div>
      </div>
    );
  }
}

export default Slider;