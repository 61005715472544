import styled from "styled-components";
import { Colors } from "../../../assets/colors";
import { Link } from "react-router-dom";

const WrapperMyProgressInfo = styled.form`
    display: flex;
    justify-content: space-between;
    margin: 16px 0 30px 0;

    p:first-of-type {
        font-size: 12px;
        font-weight: 500;
        color: ${Colors.white};
    }
`;

const LeftColumnMyProgressInfo = styled.form`
  width: 155px;
`;

const RightColumnMyProgressInfo = styled.form`
  width: 155px;
`;

export { WrapperMyProgressInfo, LeftColumnMyProgressInfo, RightColumnMyProgressInfo };