import { api } from "../api";
const _BasePath = "/usuarios";

/*const salvar = async (params) => {
  try {
    const response = await api.post(
      _BasePath + "/afiliadosbike",
      Object.assign({}, params)
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};*/

const ficha = async (params) => {
  try {
    const response = await api.get(_BasePath + "/afiliadosuber");
   // alert(response.data)
    return response.data;
    
  } catch (error) {
    console.log(error);
  }
};


/*const ficha = async (params) => {
  try {
    const response = await api.get(_BasePath + "/ficha");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const acceptRecommendations = async (params) => {
  try {
    const response = await api.post(_BasePath + "/recomendacao");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};*/

const apiCalls = {
   ficha,
};

export default apiCalls;
