
import { Container, WrapperList, List, Thumbnail } from "./style";
import Image from "../../assets/image/image.svg";
import FitDay from "../../../api/services/FitDay";
import { Link } from "react-router-dom";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Download from '@mui/icons-material/CloudDownload';
import React,  { useState, useEffect, useRef,  } from 'react';
import AccessibilityNewIcon from '@mui/icons-material/DirectionsRun';
import Series from '@mui/icons-material/Addchart';
import Repeticoes from '@mui/icons-material/SentimentSatisfiedAlt';
import Recuperacao from '@mui/icons-material/AccessTime';
import Seta from '@mui/icons-material/KeyboardDoubleArrowRight';
import Nivel from '@mui/icons-material/Person';
import { Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));


const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function ListaExercicios({ exercises }) {
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange2 = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const [exercicios, setExercicios] = useState();
  useEffect(() => {
    const getData = async () => {
      await FitDay.ficha().then((res) => {
        if (typeof res.resposta.length === "undefined") {
          setExercicios(Array(1).fill(res.resposta));
          return;
        }
        setExercicios(res.resposta);
      });
    };
    getData();
  }, []);
  

  return (

 


    <div>
      <Container style={{ width: '100%', margin: 0, padding: 0, borderTop: '1px solid rgb(211 211 211)'}}>
      <br />
<Grid container spacing={3}>
  <Grid item xs={3} sm={3} style={{ backgroundColor: '#a682c3', lineHeight: '9px', margin: '1px', textAlign: 'center', width: '24%', fontSize: '9px', color: '#fff', borderRadius: '10px', padding: '5px', height: '60px' }}>
 <Nivel /> <br />HIPERTROFIA<br />Nível 2
  </Grid>
  <Grid item xs={3} sm={3} style={{ backgroundColor: '#a682c3', lineHeight: '9px', margin: '1px', textAlign: 'center',width: '24%', fontSize: '9px', color: '#fff', borderRadius: '10px', padding: '5px', height: '60px' }}>
  <AccessibilityNewIcon /><br />10 SÉRIES 
  </Grid>
  <Grid item xs={3} sm={3} style={{ backgroundColor: '#a682c3', lineHeight: '9px', margin: '1px', textAlign: 'center',width: '24%', fontSize: '9px', color: '#fff', borderRadius: '10px', padding: '5px', height: '60px' }}>
  <Repeticoes /><br />10 à 12<br />REPETIÇÕES
  </Grid>
  <Grid item xs={3} sm={3} style={{ backgroundColor: '#a682c3', lineHeight: '9px', margin: '1px', textAlign: 'center', width: '24%', fontSize: '9px', color: '#fff', borderRadius: '10px', padding: '5px', height: '60px' }}>
  <Recuperacao /><br />RECUP.<br />1 MIN.
  </Grid>
</Grid>
</Container><br /><br />

<Grid container  spacing={3} justify="center" alignItems="center" >
  <Grid item xs={4} sm={4} style={{ lineHeight: '9px', margin: '1px', textAlign: 'center', width: '40%', fontSize: '12px', color: 'rgb(241, 53, 83)', borderRadius: '10px', padding: '5px', height: '60px' }}>
<br />INÍCIO<br /><br /><br /><br /><span style={{ background: 'rgb(241, 53, 83)', padding: '15px', borderRadius:'500px', color:'#fff', top: '-5px', position: 'relative' }}  > 13</span><br/><br /><br />OUT
  </Grid>
  <Grid item xs={4} sm={4} style={{  lineHeight: '9px', margin: '1px', textAlign: 'center',width: '15%', fontSize: '12px', color: 'rgb(241, 53, 83)', borderRadius: '10px', padding: '5px', height: '60px' }}>
 <br /> <br /> <Seta style={{fontSize: '40px'}} />
  </Grid>
  <Grid item xs={4} sm={4} style={{ lineHeight: '9px', margin: '1px', textAlign: 'center', width: '40%', fontSize: '12px', color: 'rgb(241, 53, 83)', borderRadius: '10px', padding: '5px', height: '60px' }}>
<br />FIM<br /><br /><br /><br /><span style={{ background: 'rgb(241, 53, 83)', padding: '15px', borderRadius:'500px', color:'#fff', top: '-5px', position: 'relative' }}  > 02</span><br/><br /><br />NOV
  </Grid> </Grid> <br /><br />

  <br />
  
      {exercicios && (
        <div style={{borderTop:'0px solid rgba(0, 0, 0, 0.12)'}}>
          {(() => {
            const results = [];
            const start = 65; // Valor ASCII da letra "A"
            for (let i = 0; i < exercicios.length; i++) {
              const contador = String.fromCharCode(start + i);
              const exercicio = exercicios[i];
              
              results.push(
                <Container style={{borderTop:'0px solid rgba(0, 0, 0, 0.12)'}} key={exercicio.id} >                 
        
        {  /*    <Link
                    target="_blank"
                    to={"https://youtu.be/" + exercicio?.video}
                  >
                <Thumbnail src={exercicio?.thumb} /> 
                  </Link>*/}
                  <div style={{borderTop:'0px'}}>
                  <Accordion onChange={handleChange2('panel1')} style={{
      textAlign: 'center !important', marginBottom: '3px', borderTop: '0px solid rgba(0, 0, 0, 0.12)'
    }}>
        <AccordionSummary   style={{
      backgroundColor: 'rgb(241, 53, 83)',
      borderRadius: '8px',
      textAlign: 'center !important',
      borderTop: '0px',
      display: 'flex',  
      justifyContent: 'center',
      borderTop: '0px solid rgba(0, 0, 0, 0.12)',
          
    }} aria-controls="panel1d-content" id="panel1d-header">
       <Typography style={{ color: '#fff', fontSize: '18px !important', textAlign: 'center !important', display: 'flex',  justifyContent: 'center',} } >TREINO {contador}</Typography></AccordionSummary> 
             
{exercicio?.videos.map((video, index) => (
  <div key={index}>

<Accordion onChange={handleChange2('panel1')} style={{
      textAlign: 'center !important', marginBottom: '3px'
    }}>
        <AccordionSummary   style={{
      backgroundColor: 'rgb(166, 130, 195)',
      borderRadius: '8px',
      textAlign: 'center !important',
      
    }} aria-controls="panel1d-content" id="panel1d-header">
       <Typography style={{ color: '#fff', fontSize: '15px !important', textAlign: 'center !important'} } > {video.nome}</Typography></AccordionSummary>          

          <AccordionDetails > 
     <iframe src={`https://www.youtube.com/embed/${video.exercicios}`} title={video.nome} width="100%" frameBorder="0" allowFullScreen></iframe>
      <p>{video.descricao}</p>
          </AccordionDetails >
          </Accordion>   
  </div>
))}
        

          <AccordionDetails >
          </AccordionDetails >
          </Accordion>
          
                    
                  </div>
                </Container>
              );
            }
            return results;
          })()}
        </div>
      )}
    </div>
  );
     
}

export default ListaExercicios;
