import React, { useEffect, useState } from "react";
import { Container, WrapperList, List, Thumbnail } from "./style";
import Image from "../../assets/image/image.svg";
import FitDay from "../../../api/services/FitDay";
import { Link } from "react-router-dom";

function ListExercises({ exercises }) {
  const [exercicios, setExercicios] = useState();
  useEffect(() => {
    const getData = async () => {
      await FitDay.ficha().then((res) => {
        if (typeof res.resposta.length === "undefined") {
          setExercicios(Array(1).fill(res.resposta));
          return;
        }
        setExercicios(res.resposta);
      });
    };
    getData();
  }, []);

  return (
    <div>
      {exercicios?.map((exercicio) => {
        return (
          <Container>
            <Link target="_blank" to={"https://youtu.be/" + exercicio?.video}>
              {/* <img src={Image} alt="" /> */}
              <Thumbnail src={exercicio?.thumb} />
            </Link>
            <div>
              <p className="textBold">{exercicio?.nome}</p>
              <WrapperList>
                <List>
                  <p>Séries</p>
                  <p>{exercicio?.series}</p>
                </List>
                <List>
                  <p>Repetições</p>
                  <p>{exercicio?.repeticoes}</p>
                </List>
                <List>
                  <p>Descanso</p>
                  <p>{exercicio?.descanco}s</p>
                </List>
              </WrapperList>
            </div>
          </Container>
        );
      })}
    </div>
  );
}

export default ListExercises;
