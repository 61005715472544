import React, {useState} from 'react'
import './Slider.css'
import dataSlider from './dataSlider'
import Avatar from '../../../assets/image/avatar.png';
import StarRatingComponent from '../StarRating';
export default function Slider() {

    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if(slideIndex !== dataSlider.length){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === dataSlider.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    return (
        <div className="container-slider-evaluate">
            {dataSlider.map((obj, index) => {
                return (
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide-evaluate active-anim" : "slide-evaluate"}
                    >   
                            <div className='avatar'>
                                <img src={Avatar} alt="" />
                                <p>Pedro Gabriel Nibon</p>
                                <div>
                                </div>
                            </div>
                    </div>
                    
                )
            })}
            <div className='star-rating'>
                <StarRatingComponent />
            </div>
            
        </div>
    )
}