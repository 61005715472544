import React from 'react';
import { ContainerLogin, WrapperControlPanel, ControlPanel } from './style';
import Header from '../../layout/Header';
import TabBar from '../../layout/TabBar';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import { EcommerceProducts } from '../../layout/Produtos';
//import { EcommerceCostBreakdown } from '../../layout/Afiliados/EcommerceCostBreakdown';
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Adelmo from '../../assets/image/afiliados/diagnose.jpg';
import Face6Icon from '@mui/icons-material/Face6';
import Iconegeral from '@mui/icons-material/Diversity3';
import Iconepagamentos from '@mui/icons-material/AttachMoney';
import Iconeprodutos from '@mui/icons-material/ShoppingCart';


import { useState, useEffect } from 'react';

import {
   Card,
  CardContent,
  CardHeader,
  Stack,
  Unstable_Grid2 as Grid
} from  '@mui/material';
import AfiliadosBike from '../../layout/AfiliadosBike';
import AfiliadosUber from '../../layout/AfiliadosUber';
import AfiliadosSuplementos from '../../layout/AfiliadoSuplementos';

const selectedtab = {
  backgroundColor: 'red',
  color: 'white',
  padding: '10px',
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
} 

function SubTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`sub-tabpanel-${index}`}
    aria-labelledby={`sub-tab-${index}`}
    className={value === index ? "selectedtab" : ""}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 0 }}>
        <Typography style={{ fontSize: '9px' }}>{children}</Typography>

      </Box>
    )}
  </div>
  );
}



SubTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};




function SubTab(props) {
  const [currentTab, setCurrentTab] = useState(0);
  const { label, subTabs, style } = props;

  const handleTabClick = (index) => {
    setCurrentTab(index);
  };
  

  return (
    <div style={style}>
      <div>
        {subTabs.map((tab, index) => (
          <label
            key={index}
            style={{
             
              backgroundColor: currentTab === index ? '#e73353' : '#999',
              fontSize: currentTab === index ? '12px' : '12px',
              padding: currentTab === index ? '15px' : '15px',
              borderRadius: currentTab === index ? '500px' : '500px',
              color: currentTab === index ? '#fff' : '#fff',
            }}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </label>
        ))}
      </div>
      <div>
        {subTabs.map((tab, index) => (
          <div key={index} hidden={currentTab !== index}>
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

SubTab.propTypes = {
  label: PropTypes.string.isRequired,
  subTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};


  
  export default function Afiliados() {
    const [value, setValue] = React.useState(0);

    const styles = {
      '.multiline-cell': {
        whiteSpace: 'pre-wrap',
      },
    };

  const rows: GridRowsProp = [
  { id: 1, col1: 'R$ 23,50', col2: '23/02/2023', col3:'teste', col4:'Bike de Spinning', col5:'PAGO' },
  { id: 2, col1: 'R$ 50,00', col2: '29/02/2023', col3:'teste', col4:'Suplemento Pratique',  col5:'PAGO' },
  { id: 3, col1: 'R$ 115,20', col2: '15/03/2023', col3:'teste', col4:'Bike de Spinning',  col5:'PAGO'},
];

const columns: GridColDef[] = [
  { field: 'col1', headerName: 'Valor', width: 20, flex: 1 ,  width: 120,
    cellClassName: (params) => {
      if (params.field === 'col1') {
        return 'multiline-cell'; // nome da classe CSS
      }
      return '';
    },
  },
  { field: 'col2', headerName: 'Data', width: 20, flex: 1 ,  width: 120,
    cellClassName: (params) => {
      if (params.field === 'col2') {
        return 'multiline-cell'; // nome da classe CSS
      }
      return '';
    },
  },
  { field: 'col3', headerName: 'Valor', width: 20, flex: 1 ,  width: 120,
    cellClassName: (params) => {
      if (params.field === 'col1') {
        return 'multiline-cell'; // nome da classe CSS
      }
      return '';
    },
  },
  { field: 'col4', headerName: 'Data', width: 20, flex: 1 ,  width: 120,
    cellClassName: (params) => {
      if (params.field === 'col2') {
        return 'multiline-cell'; // nome da classe CSS
      }
      return '';
    },
  },
  { field: 'col5', headerName: 'Data', width: 20, flex: 1 ,  width: 120,
    cellClassName: (params) => {
      if (params.field === 'col2') {
        return 'multiline-cell'; // nome da classe CSS
      }
      return '';
    },
  },


];

  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

   // const { cost, profit, sales } = props;

  //const formattedCost = numeral(cost).format('$0.[0]a');
 // const formattedProfit = numeral(profit).format('$0.[0]a');
  //const formattedSales = numeral(sales).format('$0.[0]a');

  const sales = '50';
  const formattedSales = '16';
  const formattedProfit = '3';
  const formattedCost = '13';
  const valorreceber = '71.38';

  const useStyles = makeStyles((theme) => ({
    selected: {
      backgroundColor: 'red',
      color: 'white',
      fontWeight: 'bold',
    },
  }));

 // const formattedCost = numeral(cost).format('$0.[0]a');
  //const formattedProfit = numeral(profit).format('$0.[0]a');
 // const formattedSales = numeral(sales).format('$0.[0]a');
  
    return (
        <ContainerLogin>
        <WrapperControlPanel>
          <ControlPanel>
            <Header />
            <p></p> <p></p><br/>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs  onChange={handleChange} 
  textColor="#fff"
  borderRadius="10"
  indicatorColor="purple" 
  aria-label="secondary tabs example"   value={value} 
  sx={{
    justifyContent: "center !important",
    display: "flex",
    
  }}
   >

    
            <Tab label="Geral"  icon={<Iconegeral />} {...a11yProps(0)}  sx={{
      background: value === 0 ? "#fff" : "",
      color: value === 0 ? "#ff2426" : "#999",
      boxShadow: value === 0 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      padding: value === 0 ? "0px" : "0px",
      borderRadius: 10,
      fontSize: "0.600rem",
    }}
    value={0} />
            <Tab
             label="Pagamentos" icon={<Iconepagamentos />} {...a11yProps(1)}
             sx={{
              background: value === 1 ? "#fff" : "",
              color: value === 1 ? "#ff2426" : "#999",
              boxShadow: value === 1 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
              padding: value === 1 ? "0" : "#999",
              borderRadius: 10,
              fontSize: "0.600rem",
            }}value={1}  />
          
            
           
            <Tab label="Produtos" icon={<Iconeprodutos />} {...a11yProps(2)} 
sx={{
  background: value === 2 ? "#fff" : "",
  color: value === 2 ? "#ff2426" : "#999",
  boxShadow: value === 2 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
  padding: value === 2 ? "0" : "#999",
  borderRadius: 10,
  fontSize: "0.600rem",
}}value={2}  />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
        <Card  >
        <Typography
                  color="text.secondary"
                  variant="body2"
                  style={{
                    textAlign: 'center',
                    color: 'rgb(241, 53, 83)',
                    fontSize: '15px',
                    marginTop: '15px',
                    marginBottom: '-5px'
                  }}
                >
                 Minhas Vendas
                </Typography>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            xs={6}
            md={4}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{
                backgroundColor: '#fef2f2',
                borderRadius: 2.5,
                px: 3,
                py: 4,
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '0!important',
                paddingRight: '0!important'
                
              }}
            >
              <Box
                sx={{
                  flexShrink: 0,
                  height: 48,
                  width: 48,
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                <img src="/assets/iconly/iconly-glass-chart.svg" />
              </Box>
              <div>
                <Typography
                  color="text.secondary"
                  variant="body2"
                >
                  Total
                </Typography>
                <Typography variant="h5">
                  {formattedSales}
                </Typography>
              </div>
            </Stack>
          </Grid>
          <Grid
            xs={6}
            md={4}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{
                backgroundColor: '#fffaec',
                borderRadius: 2.5,
                px: 3,
                py: 4,
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '0!important',
                paddingRight: '0!important'
              }}
            >
              <Box
                sx={{
                  flexShrink: 0,
                  height: 48,
                  width: 48,
                  '& img': {
                    width: '100%'
                  }
                }}
              > <img src="/assets/iconly/iconly-glass-tick.svg" />
                
              </Box>
              <div>
                <Typography
                  color="#8f4868"
                  variant="body2"
                >
                  Pagas
                </Typography>
                <Typography variant="h5">
                  {formattedCost}
                </Typography>
              </div>
            </Stack>
          </Grid>
          <Grid
            xs={6}
            md={4}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{
                backgroundColor: '#f1fdf9',
                borderRadius: 2.5,
                px: 3,
                py: 4,
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '0!important',
                paddingRight: '0!important'
              }}
            >
              <Box
                sx={{
                  flexShrink: 0,
                  height: 48,
                  width: 48,
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                <img src="/assets/iconly/iconly-glass-discount.svg" />
              </Box>
              <div>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  fontSize='8px !important'
                >
                 À Receber
                </Typography>
                <Typography variant="h5">
                  {formattedProfit}
                </Typography>
              </div>
            </Stack>
          </Grid>

          <Grid
            xs={6}
            md={4}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{
                backgroundColor: '#f1fdf9',
                borderRadius: 2.5,
                px: 3,
                py: 4,
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '0!important',
                paddingRight: '0!important'
              }}
            >
              <Box
                sx={{
                  flexShrink: 0,
                  height: 48,
                  width: 48,
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                <img src="/assets/iconly/iconly-glass-paper.svg" />
              </Box>
              <div>
                <Typography
                  color="text.secondary"
                  variant="body2"
                >
                 Em Aberto R$
                </Typography>
                <Typography variant="h5">
                {valorreceber}
                </Typography>
              </div>
            </Stack>
          </Grid>
         {/*  <EcommerceProducts
                  products={[
                    {
                      id: '5eff2512c6f8737d08325676',
                      category: 'Accessories',
                      image: '/assets/products/product-1.png',
                      name: 'Healthcare Erbology',
                      sales: 13153
                    },
                    {
                      id: '5eff2516247f9a6fcca9f151',
                      category: 'Accessories',
                      image: '/assets/products/product-2.png',
                      name: 'Makeup Lancome Rouge',
                      sales: 10300
                    },
                    {
                      id: '5eff251a3bb9ab7290640f18',
                      category: 'Accessories',
                      name: 'Lounge Puff Fabric Slipper',
                      sales: 5300
                    },
                    {
                      id: '5eff251e297fd17f0dc18a8b',
                      category: 'Accessories',
                      image: '/assets/products/product-4.png',
                      name: 'Skincare Necessaire',
                      sales: 1203
                    },
                    {
                      id: '5eff2524ef813f061b3ea39f',
                      category: 'Accessories',
                      image: '/assets/products/product-5.png',
                      name: 'Skincare Soja CO',
                      sales: 254
                    }
                  ]}
                />
               <EcommerceCostBreakdown
                  chartSeries={[14859, 35690, 45120, 25486]}
                  labels={['Strategy', 'Outsourcing', 'Marketing', 'Other']}
                />*/}

        </Grid>
      </CardContent>
    </Card>
<p></p>
<Card><CardContent>    <div style={{ height: 300, width: "100%" }}>
<DataGrid 
  rows={rows} 
  columns={columns} 
  style={{ fontSize: '10px' }} 
  autoHeight 
/>

    </div></CardContent> </Card>
    <p></p><p></p>
        </TabPanel>


        <TabPanel value={value} index={1}>
        
         <div style={{ height: 300, width: "100%" }}>
      <DataGrid rows={rows} columns={columns}  style={{ fontSize: '12px' }}  />
    </div>
        </TabPanel>


        <TabPanel value={value} index={2}>
       
        <SubTab
  label="Item One"
  subTabs={[
    { 
      label: 'Uber',  
      content: <div style={{ height: 500, width: "100%", padding: 0, marginTop: 25 }}>
        <AfiliadosUber />
      </div> 
    },
    { 
      label: 'Bike',  
      content: <div style={{ height: 500, width: "100%", padding: 0, marginTop: 25 }}>
        <AfiliadosBike />
      </div> 
    },
    { 
      label: 'Suplementação', 
      content: <div style={{ height: 500, width: "100%", padding: 0, marginTop: 25 }}>
         <AfiliadosSuplementos />
      </div> 
    },
    { 
      label: 'Diversos', 
      content:  <div style={{ height: 500, width: "100%", padding: 0, marginTop: 25 }}>
        <DataGrid rows={rows} columns={columns} style={{ fontSize: '12px' }} />
      </div> 
    },
  ]}
  style={{
   
    // aplicar estilo ao label atual
    '&[aria-selected="true"]': {
      backgroundColor: 'red',
      fontSize: '10px',
      marginTop: '15px',
    },
    // aplicar estilo aos outros labels
    '&:not([aria-selected="true"])': {
      backgroundColor: 'gray',
      fontSize: '10px'
    }
  }}
/>


      
        </TabPanel>
      </Box><TabBar />
        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
    );
  }
  

