import React from 'react';
import { ContainerLogin, WrapperControlPanel, ControlPanel } from './style';
import Header from '../../layout/Header';
import TabBar from '../../layout/TabBar';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LogoCanal from '../../assets/image/canal.png';

import { useState, useEffect } from 'react';

import {   Card,
  CardContent,
  CardHeader,
  Stack,
  Unstable_Grid2 as Grid
} from  '@mui/material';
import LayoutCanal from '../../layout/LayoutCanal';  


const selectedtab = {
  backgroundColor: 'red',
  color: 'white',
  padding: '10px',
};



  export default function Canal() {
    const [value, setValue] = React.useState(0);
   


    return (
        <ContainerLogin > 
        <WrapperControlPanel>
          <ControlPanel>
            <Header />
            <p></p> <p></p><br/>
            
      <Box sx={{ width: '100%', height: '100%'  }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  <img src={LogoCanal} style={{ maxWidth: "100%", width: "50%" }} />
 
</div>   
      <Card  style={{
  width: "90%",
  height: "100%",
  zIndex: "99999",
  borderRadius: "15px 15px 15px 15px",
  margin: "0 auto", // centraliza horizontalmente
  display: "flex", // permite centralizar verticalmente
  alignItems: "center", // centraliza verticalmente
  justifyContent: "center" // centraliza horizontalmente
}}><CardContent>    <div style={{ height: '100%', width: "100%" }}>

<LayoutCanal />

    </div></CardContent> </Card>      

    
      </Box></Box><TabBar />
        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
    );
  }
  

