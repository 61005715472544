import styled from "styled-components";
import { Colors } from "../../../assets/colors";
import { Link } from "react-router-dom";

const Container = styled.div`
    flex:0.2;
    background-color: ${Colors.gray3};
`;

const WrapperLastMonth = styled.div`
    padding: 16px;
`;

const WrapperLastMonthInfo = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
    margin: 16px;
`;

const LastMonthInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 64px;
    gap: 6px;

    p {
        color: ${Colors.white};
        font-size: 12px;
        font-weight: 300;
    }
`;

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 3px solid ${Colors.red_light};
    border-radius: 20px;

    p {
        font-size: 16px;
        font-weight: 500;
    }
`;

export { Container, WrapperLastMonth, WrapperLastMonthInfo, LastMonthInfo, Circle };