import { WrapperMyProgressDays, MyProgressDaysContent, Days } from "./style";

function MyProgressDays({ percentage, workoutNumber }) {

    return (
        <WrapperMyProgressDays>
            <MyProgressDaysContent>
                <Days>
                    <p className="btn_green">05</p>
                </Days>
                <Days>
                    <p className="btn_green">06</p>
                </Days>
                <Days>
                    <p className="btn_green">07</p>
                </Days>
                <Days>
                    <p className="btn_green">08</p>
                </Days>
                <Days>
                    <p className="btn_red">09</p>
                </Days>
                <Days>
                    <p className="btn_white">10</p>
                </Days>
                <Days>
                    <p className="btn_white">11</p>
                </Days>
                <Days>
                    <p className="btn_white">12</p>
                </Days>
                <Days>
                    <p className="btn_white">13</p>
                </Days>
                <Days>
                    <p className="btn_white">14</p>
                </Days>
                <Days>
                    <p className="btn_white">15</p>
                </Days>
                <Days>
                    <p className="btn_white">16</p>
                </Days>
                <Days>
                    <p className="btn_white">17</p>
                </Days>
                <Days>
                    <p className="btn_white">18</p>
                </Days>    
            </MyProgressDaysContent>  
        </WrapperMyProgressDays>
    )
}

export default MyProgressDays;