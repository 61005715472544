import React, { useEffect, useState } from "react";
import {
  ContainerLogin,
  WrapperControlPanel,
  ControlPanel,
  WrapperButton,
  Button,
  ListHeader,
  SelectAllButton,
  ExercisesContainer,
  ListHeaderCol,
  ListHeaderRow,
  Icon,
  
} from "./style";

import HeaderBar from "../../layout/HeaderBar";
import LinkButton from "../../form/LinkButton";

import Image from "../../assets/image/image-change-exercises.svg";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../assets/colors";
import Exercise from "../../layout/AfiliadosBase";
//import Fitday from "../../../api/services/FitDay";
import Fitday from "../../../api/services/Afiliados-Suplementos";

import EsqueletoBike from "../../pages/Afiliados/EsqueletoBike";
import Loja from "../../../api/services/Afiliados-Bike";


function AfiliadosSuplementos() {
  /*const [id, setRecommendations] = useState();
 
  const getData = async () => {
    await Loja.id().then((res) =>
      setRecommendations(res.resposta)
    );
  };

 

  useEffect(() => {
    getData();
    // console.log(state);
  }, []);

  return (

        <EsqueletoBike
        data={id}                  
                     />
    
     
  
  );
}

export default AfiliadosBike;*/


const [exercicios, setExercicios] = useState();
  const [selected, setSelected] = useState([]);

  const selectExercise = (e, exercise) => {
    e.preventDefault();
    const temp = selected;
    temp.push(exercise);
    setSelected(temp);
  };

  useEffect(() => {
    const getData = async () => {
      await Fitday.ficha().then((res) => {
        if (typeof res.resposta.length === "undefined") {
          setExercicios(Array(1).fill(res.resposta));
          return;
        }
        setExercicios(res.resposta);
      });
    };
    getData();
  }, []);

  return (
    <ContainerLogin onSubmit={(e) => e.preventDefault()}>
      <WrapperControlPanel>
        <ControlPanel>
        
        
          <ExercisesContainer>
            {exercicios
              ? exercicios.map((exercise, index) => (
                  <Exercise
                    data={exercise}
                   
                  />
                ))
              : null}
          </ExercisesContainer>
          <WrapperButton>
            <Button>
              <LinkButton to="/" text="Cancelar" />
              <LinkButton to="/days-workout" text="Confirmar" />
            </Button>
          </WrapperButton>
        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
  );
}
export default AfiliadosSuplementos;