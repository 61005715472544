import styled from "styled-components";
import { Colors } from "../../assets/colors";

const TextTitle = styled.div`
    color: ${Colors.red_light};
    font-size: 32px;
    font-weight: 500;
`;


export { TextTitle };