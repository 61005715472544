import styled from "styled-components";
import { Colors } from "../../assets/colors";
import { Link } from "react-router-dom";

const Container = styled.div`
    padding: 16px;
    display: flex;
    border-top: 1px solid ${Colors.gray4};
    display: flex;
    gap: 16px;

    p {
        font-size: 16px;
        font-weight: 400;
    }
`;

const WrapperList = styled.div`
    display: flex;
    gap: 24px;
    padding: 8px 0;

`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
        font-size: 12px;
    }
`;

export const Thumbnail = styled.img`
    width: 8vw;
    height: 8vw;
    border-radius: 10%;
`;


export { Container, WrapperList, List };