import { Link } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../assets/colors";

export const ExerciseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
//  justify-content: space-between;
  padding: 1%;
  /* height: 20%; */
  width: 100%;
  gap: 0 3%;
  border: solid;
  border-width: 0 0 0 0;
  border-bottom-color: ${Colors.gray1};
  border-bottom-width: 1px;
`;
export const ExerciseSelect = styled.button`
  /* margin: 0 3%; */
  border-radius: 10000px;
  width: 10px;
  height: 10px;
  background-color: ${(props) =>
    props.selected ? Colors.red_light : Colors.gray2};
  border-color: ${Colors.red_light};
  border-width: 1px;
`;
export const ExerciseImageContainer = styled.div`
  /* background-color: white; */
  /* border-radius: 10px; */
  /* width: 100%;
  height: 100%; */
  /* flex: 1; */
`;

export const ExerciseImage = styled.img`
  width: 20vw;
  height: 20vw;
  border-radius: 10%;
`;

export const ExerciseCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ExerciseRow = styled.div`
  display: flex;
  flex-direction: row;
  p {
    color: ${Colors.white};
  }
  margin: 3% 0;
`;

export const ExerciseInfoContainer = styled.div`
  display: flex;
 // flex-direction: column;
  gap: 1vh;
`;
export const ExerciseInfoCol = styled.div``;
export const ExerciseInfoRow = styled.div`
 // display: flex;
  flex-direction: row;
  gap: 0 1vw;
  font-size: 12px;
`;

export const BotaoComissao = styled.div`
  
   background: -webkit-linear-gradient(-360deg,#e73353 0,#ff2426 100%);
  color: #fff;
  display: block !important !important;
  /* font-weight: bold; */
  font-size: 12px;
   p {
    color: #fff !important};
    display: block !important;
  }
`;



export const ExerciseTitle = styled.p`
  flex: 1;
  /* padding: 3%; */
  /* background-color: yellow; */
  font-family: Rubik;
`;

export const ExerciseInfoTitle = styled.p`
  flex: 1;
  /* padding: 3%; */
  font-family: Rubik;
`;

export const ExerciseInfoValue = styled.p`
  flex: 1;
  /* padding: 3%; */
  font-family: Rubik;
  font-size: 12px !important;
`;

export const ExerciseEdit = styled(Link)`
  /* width: 100%; */
  /* height: 53px; */
  //display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 5% 5% 5% 5%; */
  background: -webkit-linear-gradient(-360deg,#e73353 0,#ff2426 100%);
  color: ${Colors.white};
  /* font-weight: bold; */
  font-size: 12px;
  padding: 1.5%;
  text-decoration: none;
  transition: 0.5s;
  cursor: pointer;
  border: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${Colors.red_light};
  :hover {
    background-color: ${Colors.gray1};
  }
`;
