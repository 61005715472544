import styled from "styled-components";
import { Colors } from "../../assets/colors";


const ContainerLogin = styled.form`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
`;


const WrapperControlPanel = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${Colors.gray3};

    @media (max-width: 480px) {
        width: 100%;
    }

    @media (max-height: 851px) {
        height: 100%;
    }
    &.MuiGrid-item {
        padding-top: 0 !important;
      }

      
`;


const ControlPanel = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
  //  justify-content: space-between;
    align-items: center; 

    &.selected-tab {
        background-color: ${Colors.gray3};
      }

    p {
        color: rgb(184, 184, 184);
        font-size: 10px;
    }
    &.Muigrid-item{
       margin-top: 0px !important; 
    }
    
`;






export { ContainerLogin, WrapperControlPanel, ControlPanel,};