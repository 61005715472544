//import React, { useState, useEffect } from 'react';
import {
  ContainerLogin,
  WrapperControlPanel,
  ControlPanel,
  WrapperContent,
} from "./style";


import TabBarNutri from "../../layout/TabBarNutri";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Iconetreino from '../../assets/icones/tabbar/icone-treino.svg';
import IconeExame from '../../assets/icones/tabbar/icone-exame.svg';
import IconeDiagnose from '../../assets/icones/tabbar/icone-diagnose.svg';
import logonutri from '../../assets/icones/tabbar/logonutri.webp';
import IconeRecuperacao from '../../assets/icones/tabbar/recuperacao.svg';
import IconeRepeticoes from '../../assets/icones/tabbar/repeticoes.svg';
import IconeSeries from '../../assets/icones/tabbar/series.svg';
import IconeHipertrofia from '../../assets/icones/tabbar/hipertrofia.svg';
import Foto1 from '../../assets/image/banners-topo/01.png';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useState, useEffect } from 'react';
import {Card,  Stack,} from  '@mui/material';
import { width } from "@mui/system";
import Iconesetaverde from '../../assets/icones/tabbar/setaverde.svg';
import TextField from '@mui/material/TextField';



const selectedtab = {
  backgroundColor: 'red',
  color: 'white',
  padding: '10px',
};


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


function TabPanel(props) {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
} 

const Prime = <img src={logonutri}  style={{ width: 'auto' }} alt="Descrição da imagem" />;
const Imagem1 = <img src={Foto1}  style={{ width: '100%' }} alt="Descrição da imagem" />;




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // Adiciona sombra zero
}));

function TabLabel({ icon, label }) {
  return (
    <div>
      <img style={{width: '40px'}} src={icon} alt="" /><br />
      <span>{label}</span>
    </div>
  );
}


function Treinonutri() {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const [liveData, setLiveData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangea = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

 


  return (
    
    <ContainerLogin style={{  paddingTop: '0px' }}>
    <WrapperControlPanel>
        <ControlPanel >
        <br /><br />  
                <p style={{ textAlign: 'center' }}>{Prime}</p>  
                <br /> 
                 <Typography
                  color="#fff"
                  fontSize="30px"
                  variant="body2"
                  textAlign="center"
                  fontStyle="italic"
                  lineHeight="30px"
                >
                 Seja Bem-vindo, <br /><span style={{color: "rgb(71, 85, 32)"}}>Tadeu</span>
                </Typography> <br />

     
                <Box sx={{ width: '85%', margin: '0 auto', padding: '' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', borderRadius:'40', margin: '0 auto' }}>
          <Tabs  sx={{background:'#475520' , borderRadius:'20px'}}  onChange={handleChange} 
  textColor="#fff"
  borderRadius="700"
  indicatorColor="purple" 
 
  aria-label="secondary tabs example"  value={value}  >

            <Tab label={<TabLabel icon={Iconetreino} label="Meu Treino" />}  {...a11yProps(0)} sx={{ background: value === 0 ? '#323232' : '',  color: value === 0 ? '#fff' : '#fff',  borderRadius: 5, fontSize: '0.500rem',  padding: '10px',  }} value={0}  />
            <Tab label={<TabLabel icon={IconeDiagnose} label="Scanner Corporal" />}  {...a11yProps(1)} sx={{ background: value === 1 ? '#323232' : '',  color: value === 1 ? '#fff' : '#fff',  borderRadius: 5, fontSize: '0.500rem', padding: '10px',}} value={1}  />
            <Tab label={<TabLabel icon={IconeExame} label="Diagnose" />}  {...a11yProps(2)} sx={{ background: value === 2 ? '#323232' : '',  color: value === 2 ? '#fff' : '#fff',  borderRadius: 5, fontSize: '0.500rem', padding: '10px', }} value={2}  />

          </Tabs>
        </Box><br />
        <TabPanel sx={{ justifyContent: 'center' }}style={{ padding: 0 }} value={value} index={0}>      
        
        <Grid style={{ padding: 0}} xs={4} md={4}>
        <div style={{width: '100%', textAlign: 'center' }} >    <Stack
              alignItems="center"
              direction="row"
              spacing={0}
              sx={{
                backgroundColor: 'rgb(71, 85, 32)',
                borderRadius: 2.5,
                px: 1,
                py: 2,
                display: 'inline-flex',
                margin: '5px',
                paddingLeft: '10px !important',
                paddingRight: '10px !important',
                paddingTop: '5px !important',
                paddingBottom: '20px !important',
              }}
            >
              <Box
                sx={{
                  flexShrink: 1,
                  height: 40,
                  width: 40,
                textAlign: 'center',             
                 '& img': {
                    width: '100%'
                  }
                }}
              >
               <img src={IconeHipertrofia} style={{ width: '30px', margin:'0 auto' }} />
                <Typography
                  color="text.secondary"
                  fontSize = '9px'
                  textAlign = 'center'
                  variant="body2"
                  marginTop="-10px"
                >
                 Hipertrofia<br />Nível 1
                </Typography>
              </Box></Stack>

              <Stack
              alignItems="center"
              direction="row"
              spacing={0}
              sx={{
                backgroundColor: '#475520',
                borderRadius: 2.5,
                px: 1,
                py: 2,
                display: 'inline-flex',
                margin: '5px',
                paddingLeft: '10px !important',
                paddingRight: '10px !important',
                paddingTop: '5px !important',
                paddingBottom: '20px !important',
              }}
            >
              <Box
                sx={{
                  flexShrink: 1,
                  height: 40,
                  width: 40,
                textAlign: 'center',             
                 '& img': {
                    width: '100%'
                  }
                }}
              >
               <img src={IconeSeries} style={{ width: '30px', margin:'0 auto' }} />
                <Typography
                  color="text.secondary"
                  fontSize = '9px'
                  textAlign = 'center'
                  variant="body2"
                  marginTop="1px"
                >
                 3 Séries
                </Typography>
              </Box></Stack>


              <Stack
              alignItems="center"
              direction="row"
              spacing={0}
              sx={{
                backgroundColor: '#475520',
                borderRadius: 2.5,
                px: 1,
                py: 2,
                display: 'inline-flex',
                margin: '5px',
                paddingLeft: '10px !important',
                paddingRight: '10px !important',
                paddingTop: '5px !important',
                paddingBottom: '20px !important',
              }}
            >
              <Box
                sx={{
                  flexShrink: 1,
                  height: 40,
                  width: 40,
                textAlign: 'center',             
                 '& img': {
                    width: '100%'
                  }
                }}
              >
               <img src={IconeRepeticoes} style={{ width: '30px', margin:'0 auto' }} />
                <Typography
                  color="text.secondary"
                  fontSize = '9px'
                  textAlign = 'center'
                  variant="body2"
                  marginTop="-5px"
                  lineHeight="10px"
                >
                 10 a 12<br />Repetições
                </Typography>
              </Box></Stack>


              <Stack
              alignItems="center"
              direction="row"
              spacing={0}
              sx={{
                backgroundColor: '#475520',
                borderRadius: 2.5,
                px: 1,
                py: 2,
                display: 'inline-flex',
                margin: '5px',
                paddingLeft: '10px !important',
                paddingRight: '10px !important',
                paddingTop: '5px !important',
                paddingBottom: '20px !important',
              }}
            >
              <Box
                sx={{
                  flexShrink: 1,
                  height: 40,
                  width: 40,
                textAlign: 'center',             
                 '& img': {
                    width: '100%'
                  }
                }}
              >
               <img src={IconeRecuperacao} style={{ width: '30px', margin:'0 auto' }} />
                <Typography
                  color="text.secondary"
                  fontSize = '9px'
                  textAlign = 'center'
                  variant="body2"
                  marginTop="-7px"
                >
                 Recup.<br />1 Minuto
                </Typography>
              </Box></Stack></div>

                       

             <br />
              <div>
      <Accordion style={{ backgroundColor: '#475520', textAlign: 'center', borderRadius:'10px', marginBottom:'5px', minHeight: '40px' }} onChange={handleChangea('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography style={{ margin: '0 auto', fontSize: '12px' }}>TREINO A</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion style={{ backgroundColor: '#475520', textAlign: 'center', borderRadius:'10px', marginBottom:'5px', minHeight: '40px' }} onChange={handleChangea('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography style={{ margin: '0 auto', fontSize: '12px' }}>TREINO B</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ backgroundColor: '#475520', textAlign: 'center', borderRadius:'10px', marginBottom:'5px', minHeight: '40px' }} onChange={handleChangea('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography style={{ margin: '0 auto', fontSize: '12px' }}>TREINO C</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
          <br />    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Grid style={{ width: 'auto' }} container spacing={2}>
    <Grid>
    <Item style={{ backgroundColor: "rgb(171, 197, 110)", color: 'rgb(71, 85, 32)' }} item xs={6}>
  <div style={{  fontSize: '12px', marginBottom: '-10px' }}>INÍCIO</div>
  <div style={{ marginBottom: '-10px',  fontSize: '30px' }}>05</div>
  <div style={{ fontSize: '10px', marginBottom: '-5px' }}> OUT / 23 </div><br /> 
</Item>
    </Grid>

    <Grid>
    <Item style={{ backgroundColor: "rgb(171, 197, 110)", color: 'rgb(71, 85, 32)' }} item xs={6}>
  <div style={{  fontSize: '12px', marginBottom: '-10px', color: 'rgb(71, 85, 32)' }}>  <img src={Iconesetaverde} alt="Pratique em Casa" /> </div>
  
</Item>
    </Grid>
    <Grid>
    <Item style={{ backgroundColor: "rgb(171, 197, 110)", color: 'rgb(71, 85, 32)' }} item xs={6}>
  <div style={{  fontSize: '12px', marginBottom: '-10px' }}>INÍCIO</div>
  <div style={{ marginBottom: '-10px',  fontSize: '30px' }}>05</div>
  <div style={{ fontSize: '10px', marginBottom: '-5px' }}> OUT / 23 </div><br /> 
</Item>
    </Grid>
    

  </Grid>
</div>
<div style={{  fontSize: '12px', textAlign: 'center' }}>Meu Lembrete</div>
<TextField
      id="long-text-field"
      label="Lembrete Lembrete Lembrete Lembrete Lembrete Lembrete Lembrete Lembrete Lembrete Lembrete Lembrete Lembrete"
      multiline
      maxRows={8}
      placeholder="Lembrete Lembrete Lembrete Lembrete Lembrete Lembrete Lembrete"
      variant="outlined"
      fullWidth
      style={{ backgroundColor: "#fff", borderRadius: "15px" }}
    />

<div style={{  fontSize: '12px', textAlign: 'center', marginTop: '15px' }}>Minha Unidade</div>
<div style={{  fontSize: '12px', textAlign: 'center', marginTop: '15px', color: '#fff', background: '#475520', padding: '5px', width: 'auto', borderRadius: '700px', width:'50%', margin:'0 auto' }}>Cachoeirinha</div>
       
          </Grid>
        </TabPanel>


        <TabPanel value={value} index={1}>
        
        rrrrrrrrrrrrrrrrrrrrrrrrrr
        </TabPanel>


        <TabPanel value={value} index={2}>
       sssssssssssssssssssss
         
        </TabPanel>
      </Box>



            <TabBarNutri />
        </ControlPanel>
    </WrapperControlPanel>
</ContainerLogin>

  );
}

export default Treinonutri;


/*
import React from "react";
import {
  ContainerLogin,
  WrapperControlPanel,
  ControlPanel,
  WrapperContent,
} from "./style";

import Header from "../../layout/Header";
import TabBar from "../../layout/TabBar";
import MyProgress from "../../layout/home/MyProgress";
import MyDisposition from "../../layout/home/MyDisposition";
import LastMonth from "../../layout/home/LastMonth";

function Home() {
  return (
    <ContainerLogin>
      <WrapperControlPanel>
        <ControlPanel>
          <Header />
          <WrapperContent>
            <MyProgress />
            <MyDisposition />
            <LastMonth />
          </WrapperContent>
          <TabBar />
        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
  );
}

export default Home;
*/
