import styled from "styled-components";
import { Colors } from "../../../assets/colors";
import { Link } from "react-router-dom";

const Container = styled.form`
    flex:0.4;
    background-color: ${Colors.gray3} ;
`;
const WrapperMyProgress = styled.form`
    padding: 16px;
`;


export { Container, WrapperMyProgress };