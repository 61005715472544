import styled from "styled-components";


const ExercisesContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const ContainerButtons = styled.div`
  width: 100%;
  padding: 16px;
  background-color:#777;
`;

const WrapperButtons = styled.div`
  display: flex;
  padding: 16px 0 0 0;
  gap: 6px;
`;

const Buttons = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 109px;
  height: 74px;
  border: 1px solid #777;
  border-radius: 8px;
  background-color: #777;
  cursor: pointer;

  :hover {
    background-color: #777;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    p {
      font-size: 12px;
      color: #777;
    }
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 3px solid #777;
  border-radius: 20px;
`;


const ContainerLogin = styled.form`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const WrapperControlPanel = styled.div`
    width: 375px;
    height: 667px;
   

    @media (max-width: 480px) {
        width: 100%;
    }

    @media (max-height: 851px) {
        height: 100%;
    }
`;

const ControlPanel = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 

    p {
        color: #777};
        font-size: 14px;
    }     
`;

const WrapperContent = styled.div`
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

        ::-webkit-scrollbar {
        display: none;
        }
`; 

const WrapperOverview = styled.div`
    width: 100%;
    padding: 16px;
`;

const WrapperExercises = styled.div`
    width: 100%;
    height: 100px;
    padding: 16px;
`;

const WrapperButton = styled.div`
    width: 100%;
    padding: 16px 16px 0;
    display: flex;
    flex-direction: column;
    background-color: #777;
    margin-top: auto;
`;

const Button = styled.div`
        display: flex;
        gap: 15.5px;
        color: '#fff';
`;

export { ContainerButtons, WrapperButtons, Buttons, Circle, ContainerLogin, WrapperControlPanel,ControlPanel, WrapperContent, WrapperOverview, WrapperExercises, WrapperButton, Button, ExercisesContainer };
