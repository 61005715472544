import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeaderBar from "../../layout/HeaderBar";



import {
  ContainerLogin,
  WrapperControlPanel,
  ControlPanel,
  WrapperContent,
  ButtonTabBar
} from "./style";
import Live from '../../layout/Aula';import Header from "../../layout/Header";
import TabBar from "../../layout/TabBar";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Foto1 from '../../assets/image/banners-topo/jump-min.webp';
import Foto2 from '../../assets/image/banners-topo//yoga2.webp';



const Imagem1 = <img src={Foto1}  style={{ width: '100%' }} alt="Descrição da imagem" />;
const Imagem2 = <img src={Foto2} style={{ width: '100%' }} alt="Descrição da imagem" />;


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  paddingTop: '0px !important',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // Adiciona sombra zero
}));

function Coletivas() {
  
   return (
    <ContainerLogin style={{ padding: '10px', paddingTop: '0px' }}>
    <WrapperControlPanel>
        <ControlPanel >
           <HeaderBar to="/home" text="Coletivas" />
           <p></p><br/><br/>
           <Typography
                  color="#aaa"
                  variant="body2"
                >              
                </Typography><br /><br />
       
    <Grid style={{padding: '10px'}} container spacing={2}>
  <Grid item xs={12} md={12} style={{ paddingTop: '0px !important', boxShadow: '0' }}>
    <Item>{Imagem1}</Item>
  </Grid>
  
  <Grid item xs={12} md={12} style={{ paddingTop: '0px !important' }}>
  <Link to="/unipower">
    <Item>{Imagem2} </Item>
  </Link>
</Grid>

</Grid>
<br /><br /><br /><br /><br /><br />

            <TabBar />
        </ControlPanel>
    </WrapperControlPanel>
</ContainerLogin>

  );
}

export default Coletivas;
