import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../assets/colors";
import Image from "../../assets/image/image-change-exercises.svg";
import {
  ExerciseCol,
  ExerciseContainer,
  ExerciseEdit,
  ExerciseImage,
  ExerciseImageContainer,
  ExerciseInfoCol,
  ExerciseInfoContainer,
  ExerciseInfoRow,
  ExerciseInfoTitle,
  ExerciseInfoValue,
  ExerciseSelect,
  ExerciseTitle,
} from "./style";

function Exercise({ data}) {
  const navigate = useNavigate();

  return (
    <ExerciseContainer>
    
      <ExerciseImageContainer>
        <ExerciseImage src={data?.avatar} />
      </ExerciseImageContainer>
      <ExerciseInfoContainer>
        <ExerciseInfoRow>
          {data?.nome}<br />
          Valor: R$ 29,90 <br />
        Comissão: R$ 2,99
        </ExerciseInfoRow>
       
      </ExerciseInfoContainer>

      <ExerciseInfoContainer>
        <ExerciseInfoRow>
        <ExerciseEdit
        onClick={() => navigate("/exercises/change", { state: data })}
      >
        &nbsp;&nbsp;LINK&nbsp;&nbsp;
      </ExerciseEdit> 
        </ExerciseInfoRow>
     {/*    <ExerciseEdit
        onClick={() => navigate("/exercises/change", { state: data })}
      >
        <FontAwesomeIcon icon={faPen} color={Colors.white} size="lg" />
      </ExerciseEdit> */}
      </ExerciseInfoContainer>


    
    </ExerciseContainer>
  );
}

export default Exercise;
