import { ButtonTabBar, TabBarContainer } from "./style";

import Home from '../../assets/icones/tabbar/casa.svg';
import Workout from '../../assets/icones/tabbar/ginasio.svg';
import Central from '../../assets/icones/tabbar/central.svg';
import Points from '../../assets/icones/tabbar/points.svg';
import Profile from '../../assets/icones/tabbar/do-utilizador.svg';
import Bike from '../../assets/icones/tabbar/bike2.svg';



//import CentralBackground from '../../assets/icones/tabbar/central-background.svg';

const styles = {
    svgIcon: {
        filter: 'brightness(0) saturate(100%) invert(100%)',
      
    },
  };

function TabBarNutri() {
   
    return (
        <TabBarContainer>
            <ButtonTabBar to="/home" >
                <img  src={Home} alt="Home" style={styles.svgIcon}  />
                <p style={{ color: '#fff' }}>Início</p>

            </ButtonTabBar>
            <ButtonTabBar to="/workouts" >
                <img src={Workout} alt="Workout" style={styles.svgIcon}   />
                <p style={{ color: '#fff' }}>Treino</p>
            </ButtonTabBar>
            <ButtonTabBar to="/workouts" >
                <img src={Bike} alt="Workout" style={styles.svgIcon}   />
                <p style={{ color: '#fff' }}> Bike</p>
            </ButtonTabBar>
           
        {/*     <ButtonTabBar to="/fitday" >
                <img 
                style={{ backgroundImage:`url(${CentralBackground})`, 
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
                }} src={Central} alt="Central" />
            </ButtonTabBar>
          <ButtonTabBar to="/points" >
                <img src={Points} alt="Points" />
                <p>Pontos</p>
            </ButtonTabBar> */}
            <ButtonTabBar to="/profile" >
                <img src={Profile} alt="Profile" style={styles.svgIcon}   />
                <p style={{ color: '#fff' }}>Perfil</p>
            </ButtonTabBar>
        </TabBarContainer>
    )
}

export default TabBarNutri;

