import styled from "styled-components";
import { Colors } from "../../../assets/colors";

const Container = styled.form`
    flex:0.4;
    background-color: ${Colors.gray2} ;
`;

const WrapperMyDisposition = styled.form`
    padding: 16px 0 16px 16px;
`;

const WrapperBoxMyDisposition = styled.form`
    display: flex;
    margin: 16px 0 16px 0;
    gap: 16px;
    overflow-x: scroll;
    white-space: nowrap;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }

`;

const BoxMyDisposition = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 117px;
    width: 136px;
    padding: 16px;
    border: 1px solid gray;
    border-radius: 8px;

    span {
        color: ${Colors.red_light};
    }

    p {
        color: ${Colors.white};
        font-size: 14px;
    }
`;

const WrapperGreenBars = styled.form`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

export { Container, WrapperMyDisposition, WrapperBoxMyDisposition, 
BoxMyDisposition, WrapperGreenBars };