import React, {useState} from 'react'
import './Slider.css'
import BtnSlider from './BtnSlider'
import dataSlider from './dataSlider'
import Image from '../../../assets/image/image-slider.png';

export default function Slider() {

    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if(slideIndex !== dataSlider.length){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === dataSlider.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    return (
        <div className="container-slider">
            {dataSlider.map((obj, index) => {
                return (
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >   
                            <div className='image'>
                                <img src={Image} alt="" />
                            </div>

                            <h4>Pack deck</h4>
                            <div className='packdeck-container'>
                                <div className='packdeck'>
                                    <p>Séries</p>
                                    <p>4x</p>
                                </div>
                                <div className='packdeck'>
                                    <p>Repetições</p>
                                    <p>10 a 12</p>
                                </div>
                                <div className='packdeck'>
                                    <p>Descanso</p>
                                    <p>40s</p>
                                </div>
                            </div>
                    </div>
                    
                )
            })}
            <div className='container-button'>
                <button>Feito!</button>
            </div>

           
            <div className="container-dots">
                {Array.from({length: 5}).map((item, index) => (
                    <div 
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    ></div>
                ))}
            </div>
        </div>
    )
}