import styled from "styled-components";
import { Colors } from "../../assets/colors";

const HeaderContainer = styled.form`
    padding: 16px;
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    background-color: #fff;
    color: #aaa;
    position: absolute;
    left: 0;
    box-shadow: rgb(202, 201, 201) 8px 2px 20px;
    border-radius: 0px 0px 50px 50px;
    padding-left: 50px;
    
    a:-webkit-any-link {
        color: #aaa;
       
    }

    div {
        display: flex;
        align-items: center;
        gap: 20px;

        p {
            font-size: 17px;
            text-align: center;
           
        }
    }
`;

export { HeaderContainer };