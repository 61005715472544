import { Box, Slider, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Foto9 from '../../assets/image/banners-topo/09.webp';
import './SliderCanal.css';

const SliderCanal = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (event, newValue) => {
    setActiveIndex(newValue);
  };

  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(autoplayInterval);
    };
  }, []);

  const images = [
    {
      img: 'https://app.pratiqueemcasa.com/destaque-flash.jpg',
      title: 'Cartão Flash Benefício \n Data: 24 de fevereiro de 2023 ',
      description: 'Uma bela imagem da natureza.',
    },
    {
      img: 'https://app.pratiqueemcasa.com/felipe.jpeg',
      title: 'Cartão Flash Benefício \n Data: 24 de fevereiro de 2023 ',
      description: 'Uma bela imagem da natureza.',
    },
    {
      img: 'https://app.pratiqueemcasa.com/saver.jpg',
      title: 'Cartão Flash Benefício \n Data: 24 de fevereiro de 2023 ',
      description: 'Uma bela imagem da natureza.',
    },
    
  ];

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box className="slider-container">
        {images.map((image, index) => (
          <Box
            key={index}
            className={`slide ${index === activeIndex ? 'active' : ''}`}
            style={{ backgroundImage: `url(${image.img})`, borderRadius: '25px'}}
          >
            <Box className="slide-content">
              <Typography style={{color: '#fff', fontSize: '13px', textAlign: 'center'}} >{image.title}</Typography>
             { /* <Typography variant="body1">{image.description}</Typography> */}
            </Box>
          </Box>
        ))}
      </Box>
      <Slider
        value={activeIndex}
        onChange={handleSlideChange}
        step={1}
        marks
        min={0}
        color='secondary'
        max={images.length - 1}
      />
    </Box>
  );
};

export default SliderCanal;
