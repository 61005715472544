import { WrapperHistoricButton, HistoricButton } from "./style";

import Arrow from '../../../assets/icones/home/arrow.svg';

function MyProgressHistoricButton({ percentage, workoutNumber }) {

    return (
        <WrapperHistoricButton>
            <HistoricButton>
                <p>Visualizar todo o histórico</p>
                <img src={Arrow} alt="" />
            </HistoricButton>
        </WrapperHistoricButton>
    )
}

export default MyProgressHistoricButton;