import React, { useEffect, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import {
    Card,
  CardContent,
  
} from '@mui/material';


import {
  ContainerLogin,
  WrapperControlPanel,
  ControlPanel,
  WrapperButton,
  ListHeader,
  SelectAllButton,
  ExercisesContainer,
  ListHeaderCol,
  ListHeaderRow,
  Icon,
  
} from "./style";
import { makeStyles } from "@material-ui/core/styles";
import {
  
  SvgIcon} from '@mui/material';
  import IconePonto from '@mui/icons-material/AccessTime';
  import Papai from '@mui/icons-material/Campaign';
  import SliderCanal from "../../layout/SliderCanal";

import HeaderBar from "../../layout/HeaderBar";
import LinkButton from "../../form/LinkButton";

import Image from "../../assets/image/image-change-exercises.svg";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button } from '@mui/material';


import Exercise from "../../layout/ListaBase";
//import Fitday from "../../../api/services/FitDay";
import Fitday from "../../../api/services/Canal";
import Sac from '@mui/icons-material/HeadsetMic';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
} 

function LayoutCanal() {
   const [value, setValue] = React.useState(0);
const [exercicios, setExercicios] = useState();
  const [selected, setSelected] = useState([]);

  const selectExercise = (e, exercise) => {
    e.preventDefault();
    const temp = selected;
    temp.push(exercise);
    setSelected(temp);
  };

  useEffect(() => {
    const getData = async () => {
      await Fitday.ficha().then((res) => {
        if (typeof res.resposta.length === "undefined") {
          setExercicios(Array(1).fill(res.resposta));
          return;
        }
        setExercicios(res.resposta);
      });
    };
    getData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ContainerLogin style={{height: '100%'}} onSubmit={(e) => e.preventDefault()} >
      <WrapperControlPanel>
        <ControlPanel>
        <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <Button
    variant="contained"
    size="large"
    startIcon={<IconePonto />}
    style={{
      
      backgroundColor: 'rgb(241, 53, 83)',
      width: '100%',
      borderRadius: '400px',
      padding: '15px',
      fontSize: '13px',
      color: '#fff',
      webkitjustifycontent: 'center',
    }}
  >
 BATER PONTO
  </Button></a>
  
  <div className="blink_me" style={{margin: "10px 10px 20px", border: "1px solid rgb(221, 221, 221)", backgroundImage: "linear-gradient(rgb(204, 204, 204), rgb(255, 255, 255))", textAlign: "center", borderRadius: "15px", "--darkreader-inline-border-top": "#3d4144", "--darkreader-inline-border-right": "#3d4144", "--darkreader-inline-border-bottom": "#3d4144", "--darkreader-inline-border-left": "#3d4144", "--darkreader-inline-bgimage": "linear-gradient(#383c3e, #191b1c)"}} data-darkreader-inline-border-top="" >
  <div style={{textAlign: "center"}} className="col-12 col-md-12"><br/><font style={{color: "rgb(241, 53, 83)", fontWeight: "600", "--darkreader-inline-color": "#ff1b1b"}} data-darkreader-inline-color="">NOVA C.I</font><br/><span style={{color: "#777"}}>C.I - SAVER. Live no Youtube - Tadeu / Clev</span><br/><br/></div></div>

<div className="blink_me" style={{margin: "10px 10px 20px", border: "1px solid rgb(221, 221, 221)", backgroundImage: "linear-gradient(rgb(204, 204, 204), rgb(255, 255, 255))", textAlign: "center", borderRadius: "15px", "--darkreader-inline-border-top": "#3d4144", "--darkreader-inline-border-right": "#3d4144", "--darkreader-inline-border-bottom": "#3d4144", "--darkreader-inline-border-left": "#3d4144", "--darkreader-inline-bgimage": "linear-gradient(#383c3e, #191b1c)"}} data-darkreader-inline-border-top="" >
  <div style={{textAlign: "center"}} className="col-12 col-md-12"><br/><font style={{color: "rgb(241, 53, 83)", fontWeight: "600", "--darkreader-inline-color": "#ff1b1b"}} data-darkreader-inline-color="">NOVA C.I</font><br/><span style={{color: "#777"}}>C.I - SAVER. Live no Youtube - Tadeu / Clev</span><br/><br/></div></div>

  <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <Button
    variant="contained"
    size="large"
    startIcon={<Papai />}
    style={{
      
      backgroundColor: 'red',
      width: '100%',
      borderRadius: '400px',
      padding: '15px',
      fontSize: '13px',
      color: '#fff',
      webkitjustifycontent: 'center',
    }}
  >
FALE COM O PAPAI
  </Button></a>
<br />
<SliderCanal />
<Tabs
  onChange={handleChange}
  textColor="#fff"
  borderRadius="3"
  indicatorColor="purple"
  aria-label="secondary tabs example"
  padding="0"
  value={value}
  sx={{
    display: "flex",
    justifyContent: "center",
    width: "100%", // Adiciona largura de 100% para ocupar todo o espaço disponível
    alignItems: "center",
  }}
>
  <Tab
    label="Artigos"
    {...a11yProps(0)}
    sx={{
      background: value === 0 ? "rgb(241, 53, 83)" : "#ddd",
      color: value === 0 ? "#fff" : "rgb(241, 53, 83)",
      boxShadow: value === 0 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      padding: value === 0 ? "0" : "0",
      borderRadius: 10,
      minWidth: value === 0 ? "70px" : "70px",
      fontSize: "13px",
      fontWeight: "600",
      flexBasis: "50%", // Define largura de 50%
    }}
    value={0}
  />
   <Tab
    label="C.I"
    {...a11yProps(1)}
    sx={{
      background: value === 1 ? "rgb(241, 53, 83)" : "#ddd",
      color: value === 1 ? "#fff" : "rgb(241, 53, 83)",
      boxShadow: value === 1 ? "rgb(220 220 220) 0px 8px 10px" : "transparent",
      padding: value === 1 ? "0" : "0",
      borderRadius: 10,
      minWidth: value === 1 ? "70px" : "70px",
      fontSize: "13px",
      fontWeight: "600",
      flexBasis: "50%", // Define largura de 50%
    }}
    value={1}
  />
  
</Tabs>

 <TabPanel style={{padding: '0px'}} value={value} index={0}>
       <Card > <CardContent>  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}> 
        <br /><br />
          <ExercisesContainer style={{padding: '0px'}}>
            {exercicios
              ? exercicios.map((exercise, index) => (
                  <Exercise
                    data={exercise}
                   
                  />
                ))
              : null}
          </ExercisesContainer>
</div> </CardContent>
</Card>
  </TabPanel>

  <TabPanel style={{padding: '0px !important'}} value={value} index={1}>
       <Card style={{padding: '0px'}}> <CardContent style={{padding: '0px'}}>  <div style={{ alignItems: 'center', justifyContent: 'center' }}> 
        <div className="blink_me" style={{margin: "0px", border: "1px solid rgb(221, 221, 221)", backgroundImage: "linear-gradient(rgb(204, 204, 204), rgb(255, 255, 255))", textAlign: "center", borderRadius: "15px", "--darkreader-inline-border-top": "#3d4144", "--darkreader-inline-border-right": "#3d4144", "--darkreader-inline-border-bottom": "#3d4144", "--darkreader-inline-border-left": "#3d4144", "--darkreader-inline-bgimage": "linear-gradient(#383c3e, #191b1c)"}} data-darkreader-inline-border-top="" >
  <div style={{textAlign: "center"}} className="col-12 col-md-12"><br/><font style={{color: "rgb(241, 53, 83)", fontWeight: "600", "--darkreader-inline-color": "#ff1b1b"}} data-darkreader-inline-color="">NOVA C.I</font><br/><span style={{color: "#777"}}>C.I - SAVER. Live no Youtube - Tadeu / Clev</span><br/><br/></div></div>
<br />
<div className="blink_me" style={{margin: "0px", border: "1px solid rgb(221, 221, 221)", backgroundImage: "linear-gradient(rgb(204, 204, 204), rgb(255, 255, 255))", textAlign: "center", borderRadius: "15px", "--darkreader-inline-border-top": "#3d4144", "--darkreader-inline-border-right": "#3d4144", "--darkreader-inline-border-bottom": "#3d4144", "--darkreader-inline-border-left": "#3d4144", "--darkreader-inline-bgimage": "linear-gradient(#383c3e, #191b1c)"}} data-darkreader-inline-border-top="" >
  <div style={{textAlign: "center"}} className="col-12 col-md-12"><br/><font style={{color: "rgb(241, 53, 83)", fontWeight: "600", "--darkreader-inline-color": "#ff1b1b"}} data-darkreader-inline-color="">NOVA C.I</font><br/><span style={{color: "#777"}}>C.I - SAVER. Live no Youtube - Tadeu / Clev</span><br/><br/></div></div>

</div> </CardContent>
</Card>
  </TabPanel>




         
        </ControlPanel>
      </WrapperControlPanel>
    </ContainerLogin>
  );
}
export default LayoutCanal;