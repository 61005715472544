import { HeaderContainer, LeftColumnHeader, RightColumnContainer, Button } from './style';
import Halter from '../../assets/icones/header/halter.svg';
import { Avatar } from '@mui/material';
import Foto from '../../assets/image/avatar.png';
import Logo from '../../assets/image/logoapp.png';




function Header({ name }) {
    return (
        <HeaderContainer>
            <LeftColumnHeader>
            <Avatar
  alt="Remy Sharp"
  src={Foto}
 // src="./components/assets/image/avatar.png"
 sx={{
    position: "absolute",
    top: 8,
    right: 60,
    width: 30,
    height: 30,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.3)'
  }}
/>  
<Avatar
  alt="Remy Sharp"
  src={Logo}
  sx={{
    position: "absolute",
    width: "120px",
    marginTop: "-5px",
    left: 40,
    border: "0px",
    borderRadius: "0px"
  }}
/>


    
            </LeftColumnHeader>
            <RightColumnContainer sx={{ right: 60 }}>
            <p style={{ marginTop: '36px', fontSize: '12px' }}>Olá, Tadeu</p>
              {/*   <Button to="/fitday">
                    <img src={Halter} alt="" />
                    <p>Iniciar FitDay</p>
    </Button> */}
            </RightColumnContainer>
        </HeaderContainer>
    )
}

export default Header;