import { Button, WrapperButton } from "./styles";

function LinkButton({ to, text, onClick, disabled }) {
  return (
    <WrapperButton>
      <Button type="submit" disabled={disabled} onClick={onClick} to={disabled ? null : to}>
        {text}
      </Button>
    </WrapperButton>
  );
}

export default LinkButton;
