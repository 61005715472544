import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../assets/colors";
import Image from "../../assets/image/image-change-exercises.svg";
import {
  ExerciseCol,
  ExerciseContainer,
  ExerciseEdit,
  ExerciseImage,
  ExerciseImageContainer,
  ExerciseInfoCol,
  ExerciseInfoContainer,
  ExerciseInfoRow,
  ExerciseInfoTitle,
  ExerciseInfoValue,
  ExerciseSelect,
  ExerciseTitle,
} from "./style";

function Exercise({ select, edit, data, selected }) {
  const navigate = useNavigate();

  return (
    <ExerciseContainer>
      <ExerciseSelect selected={selected} onClick={select} />
      <ExerciseImageContainer>
        <ExerciseImage src={data?.thumb} />
      </ExerciseImageContainer>
      <ExerciseInfoContainer>
        <ExerciseInfoRow>
          <ExerciseTitle>Peck Deck</ExerciseTitle>
        </ExerciseInfoRow>
        <ExerciseInfoRow>
          <ExerciseInfoCol>
            <ExerciseInfoTitle>Séries</ExerciseInfoTitle>
            <ExerciseInfoValue>{data?.series}</ExerciseInfoValue>
          </ExerciseInfoCol>
          <ExerciseInfoCol>
            <ExerciseInfoTitle>Repetições</ExerciseInfoTitle>
            <ExerciseInfoValue>{data?.repeticoes}</ExerciseInfoValue>
          </ExerciseInfoCol>
          <ExerciseInfoCol>
            <ExerciseInfoTitle>Descanso</ExerciseInfoTitle>
            <ExerciseInfoValue>{data?.descanco}s</ExerciseInfoValue>
          </ExerciseInfoCol>
        </ExerciseInfoRow>
      </ExerciseInfoContainer>
      <ExerciseEdit
        onClick={() => navigate("/exercises/change", { state: data })}
      >
        <FontAwesomeIcon icon={faPen} color={Colors.white} size="lg" />
      </ExerciseEdit>
    </ExerciseContainer>
  );
}

export default Exercise;
