import React, { useState, useEffect } from 'react';
import {
  ContainerLogin,
  WrapperControlPanel,
  ControlPanel,
  WrapperContent,
} from "./style";

import Header from "../../layout/Header";
import TabBar from "../../layout/TabBar";
import MyProgress from "../../layout/home/MyProgress";
import MyDisposition from "../../layout/home/MyDisposition";
import LastMonth from "../../layout/home/LastMonth";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Bike2 from '../../assets/image/banners-topo/bike.jpg';
import Seta from '@mui/icons-material/KeyboardDoubleArrowRight';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconeBike from '@mui/icons-material/DirectionsBike';
import Monentum from '../../assets/image/banners-topo/monentum.webp';
import GooglePlay from '../../assets/image/banners-topo/googleplay.webp';
import Apple from '../../assets/image/banners-topo/apple.webp';
import VideoApp from '../../assets/videos/momentum.mp4';
import LogoLoja from '../../assets/image/logoloja.png';
import Zap from '../../assets/image/ZAP.png';



import {
  Card,
  CardContent,
} from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  paddingTop: '0px !important',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // Adiciona sombra zero
}));


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));


const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Bike() {
  
 

  return (
    <ContainerLogin style={{ padding: '10px', paddingTop: '0px' }}>
    <WrapperControlPanel>
        <ControlPanel >
            <Header style={{zIndex: 2}} />
            <img
      style={{
        width: '100%',
       
        marginTop: -5,
        zIndex: 0,
        borderRadius: "50px 50px 100px 100px",
      }}
      alt="Pratique em Casa"
      src={Bike2}
    />
           <p></p>

           <Card style={{ width: "100%",  marginTop: "-30px", zIndex: "99999", borderRadius: "40px 40px 15px 15px" }}><CardContent>    <div >

<br />
           <div><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <IconeBike style={{ color: 'rgb(241, 53, 83)' }} />
      <Typography variant="h6" style={{ color: 'rgb(241, 53, 83)', fontSize: '14px', textAlign: 'center' }}>
      &nbsp;Instruções
      </Typography>
    </div><br />
           <Typography style={{ textAlign:'center', fontSize:'13px', color:'#888888' }}>
           
Para uma melhor experiência, assista as aulas em uma tela maior.
Sugestão – Smart TV

Siga o Passo a passo para participar das aulas:
          </Typography>
          <br />
      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff"}}>1º Assista aulas ao vivo no YouTube</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ textAlign:'center', fontSize:'13px', color:'#888888', padding: '15px' }}>
            <img src={Monentum} style={{width: "50%"}} /><br />
          Assista nossas aulas no YouTube pela sua TV ou Computador atráves dos canais NetFit Brasil e Pratique Em Casa
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff"}}>2º Baixe o App de monitoramento</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{ textAlign:'justify', fontSize:'13px', color:'#888888', padding: '15px' }}>
          Pedale junto com todo Pelotão, se enxergue na Tela, interaja com Professor e todos na Sala NETFIT, monitore em Tempo Real RPM, Distancia, Gasto calórico, Frequência. BAIXE:
          </Typography><br />
        <div align="center">  <img src={Monentum} style={{width: "50%"}} /></div><br />
          <Grid container spacing={2}>
 
  <Grid item xs={6} md={3} style={{ paddingTop: '0px !important', boxShadow: '0 !important' }}>
    <Item><img src={Apple} style={{width: "100%"}}  /></Item>
  </Grid>
  <Grid item xs={6} md={3} style={{ paddingTop: '0px !important' }}>
    <Item><img src={GooglePlay} style={{width: "100%"}} /></Item>
  </Grid></Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff", textAlign: "left"}}>3° Como Configurar o App de Monitoramento</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{ textAlign:'justify', fontSize:'13px', color:'#888888', padding: '15px' }}>
        <video src={VideoApp} controls width="100%" />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff"}}>4º Acesso ao APP Pratique em Casa</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{ textAlign:'justify', fontSize:'13px', color:'#888888', padding: '15px' }}>
          🚲 Locando a Bike você tem acesso PREMIUM ao App de treino do Pratique Em Casa. 📲<br /><br />
👉 Com MAIS DE 2000 Treinos de Spinning de Dança, Yoga, Jump, Abdominal, Queima… Blog com dicas de saúde e você pode assistir por temporada, professor e duração de aula (5, 10, 15, 30 minutos).
          </Typography>
          <Grid container spacing={2}>
 
 <Grid item xs={6} md={3} style={{ paddingTop: '0px !important', boxShadow: '0 !important' }}>
   <Item><img src={Apple} style={{width: "100%"}}  /></Item>
 </Grid>
 <Grid item xs={6} md={3} style={{ paddingTop: '0px !important' }}>
   <Item><img src={GooglePlay} style={{width: "100%"}} /></Item>
 </Grid></Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff"}}>5º Regulagem da Bike</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{ textAlign:'justify', fontSize:'13px', color:'#888888', padding: '15px' }}>
        <iframe
  width="100%"
  src="https://www.youtube.com/embed/3WygiR4emL8"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowfullscreen
></iframe>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff"}}>6º Participar do Grupo Exclusivo Telegram</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{ textAlign:'justify', fontSize:'13px', color:'#888888', padding: '15px' }}>
          Temos também um grupo do Telegram com todos professores e a turma do pedal Pratique em casa:


          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff"}}>Quadro de Horários</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{ textAlign:'justify', fontSize:'13px', color:'#888888', padding: '15px' }}>
            
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff"}}>Solicitar manutenção</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{ textAlign:'justify', fontSize:'13px', color:'#888888', padding: '15px' }}>
        <div>
      <iframe src="https://form.typeform.com/to/tANzURg0" 
              width="100%" 
              height="500" 
              frameborder="0" 
              allowfullscreen="true" 
              style={{border: 'none', marginBottom: '-5px'}}></iframe>
      <div style={{fontFamily: 'Sans-Serif', fontSize: '12px', color: '#999', opacity: '0.5', paddingTop: '5px'}}>
        powered by <a style={{color: '#999'}} href="https://admin.typeform.com/signup?utm_campaign=tANzURg0&amp;utm_source=typeform.com-01D8JWSEMHEXSW125XSD07QEW5-pro&amp;utm_medium=typeform&amp;utm_content=typeform-embedded-poweredbytypeform&amp;utm_term=EN" target="_blank" rel="noopener noreferrer">Typeform</a>
      </div>
    </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff"}}>Loja Pratique</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{ textAlign:'justify', fontSize:'13px', color:'#888888', padding: '15px' }}>
        <img src={LogoLoja} style={{width: "100%"}}  />
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion style={{borderRadius: "15px"}}>
        <AccordionSummary style={{backgroundColor: "rgb(241, 53, 83)", borderRadius: "15px", color: "#fff"} }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{fontSize:"12px", color: "#fff"}}>Whatsapp</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{ textAlign:'center', fontSize:'13px', color:'#888888', padding: '15px' }}>
        <img src={Zap} style={{width: "50%"}}  />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ textAlign:'center', fontSize:'13px', color:'#888888', padding: '15px' }}>
      Ainda com dúvidas?<br /><br />

📞 TEL : 0800-060-15-15<br /><br />
     De Segunda a Sexta<br />

     das 09:00 às 19:00

          </Typography>
    

    </div>

    </div></CardContent> </Card>
          
      
 
<br /><br /><br /><br /><br /><br />

            <TabBar />
        </ControlPanel>
    </WrapperControlPanel>
</ContainerLogin>

  );
}

export default Bike;

